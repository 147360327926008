import { useState, useEffect } from 'react';
import { ABTest } from '@repo/alictus-common/types';
import { sortVersions } from '../utils/version_utils';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export function useUniqueValues(abTestValues: ABTest[], segmentationData: PlayerSegment[]) {
	const [availableVersions, setAvailableVersions] = useState<string[]>([]);
	const [availableCountries, setAvailableCountries] = useState<string[]>([]);
	const [availableSegmentations, setAvailableSegmentations] = useState<string[]>([]);

	useEffect(() => {
		const versions = getUniqueVersions(abTestValues);
		const countries = getUniqueCountries(abTestValues);
		const segmentations = getUniqueSegmentations(abTestValues, segmentationData);

		setAvailableVersions(versions);
		setAvailableCountries(countries);
		setAvailableSegmentations(segmentations);
	}, [abTestValues, segmentationData]);

	return { availableVersions, availableCountries, availableSegmentations };
}

function getUniqueVersions(abTestValues: ABTest[]): string[] {
	if (!abTestValues) return [];

	const uniqueVersions = new Set<string>();

	abTestValues.forEach((value) => {
		if (value.version) {
			uniqueVersions.add(value.version);
		}
	});

	const sortedVersions = sortVersions(Array.from(uniqueVersions));
	sortedVersions.unshift('Select Version');
	return sortedVersions;
}

function getUniqueCountries(abTestValues: ABTest[]): string[] {
	if (!abTestValues) return [];

	const uniqueCountries = new Set<string>();

	abTestValues.forEach((value) => {
		if (value.country) {
			uniqueCountries.add(value.country.map((c) => c.value).join(', '));
		}
	});

	const sortedCountries = Array.from(uniqueCountries).sort();
	sortedCountries.unshift('Select Country');
	return sortedCountries;
}

function getUniqueSegmentations(abTestValues: ABTest[], segmentationData: PlayerSegment[]): string[] {
	if (!abTestValues) return [];

	const uniqueSegmentations = new Set<string>();

	/*remoteConfigValues.forEach((value) => {
		value.filterOperations.forEach((filterOp) => {
			if (filterOp.Filter === 11) {
				// FilterType.Segmentation
				filterOp.Values?.forEach((v) => uniqueSegmentations.add(segmentationData.find((s) => s.uid === v)?.name || v.toString()));
			}
		});
	});*/

	segmentationData.forEach((segmentation) => {
		uniqueSegmentations.add(segmentation.name);
	});

	const sortedSegmentations = Array.from(uniqueSegmentations).sort();
	sortedSegmentations.unshift('Select Segmentation');
	return sortedSegmentations;
}
