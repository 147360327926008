import { ABTest } from '@repo/alictus-common/types';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { Platform } from '@repo/alictus-common/enums/platform';
import { FilterCondition } from '@repo/alictus-common/types';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export class ViewFilter {
	public ViewOnlyActive: boolean;
	public ViewVersion: string | null;
	public ViewCountry: string | null;
	public ViewPlatform: string | null;
	public ViewSegmentation: string | null;
	public ViewState: Number | null;
	public SegmentationData: PlayerSegment[] | null;

	constructor() {
		this.ViewOnlyActive = true;
		this.ViewVersion = null;
		this.ViewCountry = null;
		this.ViewPlatform = null;
		this.ViewSegmentation = null;
		this.SegmentationData = null;
		this.ViewState = null;
	}

	setSegmentationData(segmentationData: PlayerSegment[]): ViewFilter {
		this.SegmentationData = segmentationData;
		return this;
	}

	toggleViewOnlyActive(): ViewFilter {
		this.ViewOnlyActive = !this.ViewOnlyActive;
		return this;
	}

	checkIfFilterExists(abTestValue: ABTest, filterId: number): boolean {
		for (let i = 0; i < abTestValue.filterOperations.length; i++) {
			let filterOp = abTestValue.filterOperations[i];
			if (filterOp.Filter === filterId) return true;
		}
		return false;
	}

	checkIfSegmentationFilterExists(abTestValue: ABTest): boolean {
		if (abTestValue.filterOperations.length === 0) return false;
		for (let i = 0; i < abTestValue.filterOperations.length; i++) {
			let filterOp = abTestValue.filterOperations[i];

			let segmentationIDFromName = this.SegmentationData!.find((segment) => segment.name === this.ViewSegmentation)?.uid;
			if (filterOp.Filter === FilterType.Segmentation && filterOp.Values.includes(segmentationIDFromName!)) return true;
		}
		return false;
	}

	checkIfStateFilterExists(abTestValue: ABTest): boolean {
		if (this.ViewState === null) return false;
		if (abTestValue.state === this.ViewState) return true;
		return false;
	}

	parseVersion = (v: string): [number, number, number] => {
		const [x, y, z] = v.split('.').map(Number);
		return [x, y, z];
	};

	compareVersions = ([aX, aY, aZ]: [number, number, number], [bX, bY, bZ]: [number, number, number]): number => {
		if (aX !== bX) return aX - bX;
		if (aY !== bY) return aY - bY;
		return aZ - bZ;
	};

	checkIfVersionFilterExistsInRange(version: string | null, filterCondition: FilterCondition): boolean {
		if (!this.ViewVersion || !version) {
			return false;
		}

		const [desiredX, desiredY, desiredZ] = this.parseVersion(this.ViewVersion!);
		const versionList = version.split(',');

		if (versionList.length === 0) {
			return true;
		}

		let isInRange = true;
		for (const filter of versionList) {
			if (typeof filter !== 'string') continue;

			const [filterX, filterY, filterZ] = this.parseVersion(filter);
			const comparison = this.compareVersions([desiredX, desiredY, desiredZ], [filterX, filterY, filterZ]);

			switch (filterCondition) {
				case FilterCondition.Equal:
					return comparison === 0;
				case FilterCondition.NotEqual:
					return comparison !== 0;
				case FilterCondition.GreaterThan:
					isInRange = isInRange && comparison > 0;
					break;
				case FilterCondition.LessThan:
					isInRange = isInRange && comparison < 0;
					break;
				case FilterCondition.GreaterThanOrEqual:
					isInRange = isInRange && comparison >= 0;
					break;
				case FilterCondition.LessThanOrEqual:
					isInRange = isInRange && comparison <= 0;
					break;
				case FilterCondition.Include:
					return comparison === 0;
				case FilterCondition.Exclude:
					return comparison !== 0;
				default:
			}

			if (!isInRange) {
				return false;
			}
		}

		return isInRange;
	}

	canDisplay(abTestValue: ABTest): boolean {
		if (!this.hasFilter()) return true;

		if (this.ViewOnlyActive && abTestValue.isMock) {
			return false;
		}

		if (this.ViewVersion && !this.checkIfVersionFilterExistsInRange(abTestValue.version, abTestValue.versionFilter)) {
			return false;
		}

		if (this.ViewSegmentation && !this.checkIfSegmentationFilterExists(abTestValue)) {
			return false;
		}

		if (this.ViewPlatform) {
			const platformId = platformNameToPlatformEnum(this.ViewPlatform.toLowerCase());
			return abTestValue.platform === platformId || abTestValue.platform === Platform.All;
		}

		if (this.ViewCountry) {
			if (Array.isArray(abTestValue.country)) {
				return abTestValue.country.map((c) => c.value?.toString().toLowerCase()).includes(this.ViewCountry.toLowerCase());
			}
			return false;
		}

		if (this.ViewState !== null && this.ViewState.valueOf() >= 0) {
			return this.checkIfStateFilterExists(abTestValue);
		}

		return true;
	}

	hasFilter(): boolean {
		return !!this.ViewVersion || !!this.ViewCountry || !!this.ViewPlatform || this.ViewSegmentation !== null;
	}
}

function platformNameToPlatformEnum(platform: string): Platform {
	switch (platform) {
		case 'ios':
			return Platform.iOS;
		case 'android':
			return Platform.Android;
		default:
			return Platform.All;
	}
}
