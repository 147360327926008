import React, { useState, useEffect } from 'react';
import { Box, HStack, Checkbox, FormControl, Select } from '@chakra-ui/react';
import globalStore from '../../store';
import { ViewFilter } from '../ab_test_view_filter';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import { useUniqueValues } from '../hooks/use_unique_values';
import { ABTest, ABTestStreamView } from '@repo/alictus-common/types/ab_test';
interface ABTestHeaderProps {
	viewFilter: ViewFilter;
	segmentationData: PlayerSegment[];
	setViewFilter: React.Dispatch<React.SetStateAction<ViewFilter>>;
	remoteABTests: ABTestStreamView[];
}

const availableStates = [
	'Select State',
	'Waiting to Start',
	'Running',
	'Completed',
	'No New Users',
	'Cancelled',
	'Paused for Today',
	'Target User Count Reached',
];

export function ABTestHeader({ viewFilter, segmentationData, setViewFilter, remoteABTests }: ABTestHeaderProps) {
	let globalData = globalStore();
	const isMockCreator = globalData.user?.roles.includes('MOCK_CREATOR') || false;
	const [filteredCountry, setFilteredCountry] = useState('');
	const [filteredVersion, setFilteredVersion] = useState('');
	const [filteredPlatform, setFilteredPlatform] = useState('');
	const [filteredViewSegmentation, setViewSegmentation] = useState('');
	const [filteredState, setFilteredState] = useState('');

	const { availableVersions, availableCountries, availableSegmentations } = useUniqueValues(
		remoteABTests.map((remoteABTest) => remoteABTest.abTest),
		segmentationData,
	);

	useEffect(() => {
		setViewFilter((prevViewFilter) => {
			const updatedFilter = new ViewFilter();
			Object.assign(updatedFilter, prevViewFilter);
			updatedFilter.ViewVersion = filteredVersion !== 'Select Version' ? filteredVersion : null;
			updatedFilter.ViewPlatform = filteredPlatform !== 'Select Platform' ? filteredPlatform.toLowerCase() : null;
			updatedFilter.ViewCountry = filteredCountry !== 'Select Country' ? filteredCountry.toLowerCase() : null;
			updatedFilter.ViewSegmentation = filteredViewSegmentation !== 'Select Segmentation' ? filteredViewSegmentation : null;
			updatedFilter.ViewState = filteredState === 'Select State' ? null : availableStates.indexOf(filteredState) - 1;
			return updatedFilter;
		});
	}, [filteredVersion, filteredPlatform, filteredCountry, filteredViewSegmentation, filteredState, setViewFilter]);

	return (
		<HStack
			width="100%"
			height="60px"
			bg="gray.800"
			paddingX="10px"
			spacing="7px"
			overflowX="auto" // Allows scrolling if content overflows
			whiteSpace="nowrap" // Prevents wrapping
			alignItems="center"
		>
			<Checkbox
				flexShrink={0}
				minWidth="200px"
				isDisabled={!isMockCreator}
				onChange={() =>
					setViewFilter((prevViewFilter) => {
						const updatedFilter = new ViewFilter();
						Object.assign(updatedFilter, prevViewFilter);
						updatedFilter.ViewOnlyActive = !prevViewFilter.ViewOnlyActive;
						return updatedFilter;
					})
				}
			>
				View Hidden A/B Tests
			</Checkbox>

			<FilterSelect value={filteredPlatform} onChange={setFilteredPlatform} options={['Select Platform', 'iOS', 'Android']} />
			<FilterSelect value={filteredVersion} onChange={setFilteredVersion} options={availableVersions} />
			<FilterSelect value={filteredCountry} onChange={setFilteredCountry} options={availableCountries} />
			<FilterSelect value={filteredViewSegmentation} onChange={setViewSegmentation} options={availableSegmentations} />
			<FilterSelect value={filteredState} onChange={setFilteredState} options={availableStates} />
		</HStack>
	);
}

interface FilterSelectProps {
	value: string;
	onChange: (value: string) => void;
	options: string[];
}

function FilterSelect({ value, onChange, options }: FilterSelectProps) {
	return (
		<FormControl flex="1" minW={'200px'}>
			<Select value={value} onChange={(e) => onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</Select>
		</FormControl>
	);
}
