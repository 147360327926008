import { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	VStack,
	HStack,
	Checkbox,
	Spinner,
} from '@chakra-ui/react';

import { Form, Formik, FormikValues } from 'formik';
import axios from 'axios';
import config from '../config';
import BasicInfo from './components/BasicInfo';
import Targeting from './components/Targeting';
import Distribution from './components/Distribution';
import Variants from './components/Variants';
import SegmentedVariants from './components/SegmentedVariants';
import globalStore, { GlobalStore } from '../store';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { createExperimentRequest, updateExperiment } from './abTestApi';

import { ABTestState } from '@repo/alictus-common/enums/ab_test_state';
import { Platform } from '@repo/alictus-common/enums/platform';
import { UserTargeting } from '@repo/alictus-common/enums/user_targeting';
import { ABTest } from '@repo/alictus-common/types/ab_test';
import { abTestValidationSchema, abTestSegmentedValidationSchema } from '@repo/alictus-common/validation/ab_test_schema';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export function ABTestModal({
	title,
	isOpen,
	onClose,
	abTestToUpdate,
}: {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	abTestToUpdate?: ABTest;
}) {
	let store = globalStore();
	const toast = useToast();
	const isMockCreator = store.user?.roles.includes('MOCK_CREATOR') || false;

	const [isMock, setIsMock] = useState(false);
	const [isABTestCompletedOrCancelled, setIsABTestCompletedOrCancelled] = useState(false);
	const [isABTestNewlyCreated, setIsABTestNewlyCreated] = useState(false);
	const [abTestModal, setABTestModal] = useState<ABTest>(createDefaultABTest(isMockCreator));
	const [loading, setLoading] = useState(true);
	const [segmentationData, setSegmentationData] = useState<PlayerSegment[]>([]);

	useEffect(() => {
		const fetchSegmentationData = async () => {
			try {
				let bearerToken = 'Bearer ' + store.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/segment';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let playerSegments = response.data as PlayerSegment[];

				setSegmentationData(playerSegments);
			} catch (error) {
				console.error('Error fetching segmentation data:', error);
			} finally {
				setLoading(false);
			}
		};

		const fetchSelectedABTestData = async () => {
			if (abTestToUpdate) {
				try {
					// Fetch the selected AB test data
					let selectedABTestData = await fetchFullAbTestData(abTestToUpdate.id);
					if (selectedABTestData) {
						setABTestModal(selectedABTestData);
					}
				} catch (error) {
					console.error('Error fetching AB test data:', error);
					toast({
						title: 'Error',
						description: 'Failed to fetch AB test data. Please try again.',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				}
			}
		};

		fetchSegmentationData();

		if (abTestToUpdate) {
			fetchSelectedABTestData();
			setIsMock(abTestToUpdate.isMock);
			setIsABTestNewlyCreated(false);
		} else if (abTestToUpdate === undefined) {
			setABTestModal(createDefaultABTest(isMockCreator));
			setIsMock(isMockCreator);
			setIsABTestNewlyCreated(true);
		}
		setIsABTestCompletedOrCancelled(abTestModal.state === ABTestState.Completed || abTestModal.state === ABTestState.Cancelled);
	}, [abTestToUpdate, isMockCreator, abTestModal.state, store.gameId]);

	function updateABTest(abTest: Partial<ABTest>) {
		setABTestModal({ ...abTestModal, ...abTest });
	}

	async function fetchFullAbTestData(abTestId: number): Promise<ABTest | null> {
		return await axios
			.get(`${config.API_ENDPOINT}/game/${store.gameId}/ab_test/${abTestId}`, {
				headers: { Authorization: `Bearer ${store.JWTToken}` },
			})
			.then((response) => {
				return response.data as ABTest;
			})
			.catch((error) => {
				console.error('Error fetching full remote config:', error);
				if (error.response) {
					console.error('Response data:', error.response.data);
					console.error('Response status:', error.response.status);
				} else if (error.request) {
					console.error('No response received:', error.request);
				} else {
					console.error('Error setting up request:', error.message);
				}
				return null;
			});
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW="1250px" border={'1px'} borderColor={'gray.700'}>
				<ModalHeader>{title}</ModalHeader>
				<Formik
					initialValues={(abTestModal as FormikValues) || createDefaultABTest(isMockCreator)}
					validationSchema={toFormikValidationSchema(
						abTestModal.filterOperations.length > 0 ? abTestSegmentedValidationSchema : abTestValidationSchema,
					)}
					enableReinitialize={true}
					onSubmit={async (values, actions) => {
						// Clean up empty values

						const validationResult =
							abTestModal.filterOperations.length > 0
								? abTestSegmentedValidationSchema.safeParse(abTestModal)
								: abTestValidationSchema.safeParse(abTestModal);

						if (!validationResult.success) {
							console.error('Validation failed:', validationResult.error.issues);

							// Prepare the validation error messages
							const errorMessages = validationResult.error.issues.map((issue) => `${issue.message}`);

							// Show error toast with line breaks
							toast({
								title: 'Validation Error',
								description: (
									<Box>
										{errorMessages.map((msg, idx) => (
											<Box key={idx}>- {msg}</Box>
										))}
									</Box>
								),
								status: 'error',
								duration: 7000,
								isClosable: true,
								position: 'top',
							});

							return;
						}
						if (abTestToUpdate === undefined) {
							await handleCreateExperiment(store, abTestModal, toast, onClose);
						}

						if (abTestToUpdate !== undefined) {
							await handleUpdateExperiment(store, abTestModal!, toast, onClose);
						}
						actions.setSubmitting(false);
					}}
				>
					{(props) => (
						<Form>
							{loading && (
								<Box
									position="absolute"
									top={0}
									left={0}
									width="100%"
									height="100%"
									display="flex"
									justifyContent="center"
									alignItems="center"
									backgroundColor="rgba(0, 0, 0, 0.5)" // Semi-transparent background to indicate loading
									zIndex={10} // Ensure it appears above other elements
								>
									<Spinner size="xl" />
								</Box>
							)}
							<ModalBody>
								<VStack spacing={4}>
									<Accordion border={'1px'} borderColor={'gray.700'} width={'100%'}>
										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Basics
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{<BasicInfo abTestModal={abTestModal!} updateAbTestModal={updateABTest} />}</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Targeting
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												{
													<Targeting
														abTestModal={abTestModal!}
														updateAbTestModal={updateABTest}
														isABTestNewlyCreated={isABTestNewlyCreated}
														segmentationData={segmentationData}
													/>
												}
											</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Distribution
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{<Distribution abTestModal={abTestModal!} updateAbTestModal={updateABTest} />}</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Variants
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												{abTestModal.filterOperations.length > 0 ? (
													<SegmentedVariants
														abTestModal={abTestModal!}
														updateAbTestModal={updateABTest}
														rcValues={store.remoteConfigValues}
														isABTestNewlyCreated={isABTestNewlyCreated}
														segmentationData={segmentationData}
													/>
												) : (
													<Variants
														abTestModal={abTestModal!}
														updateAbTestModal={updateABTest}
														rcValues={store.remoteConfigValues}
														isABTestNewlyCreated={isABTestNewlyCreated}
													/>
												)}
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</VStack>
							</ModalBody>
							<ModalFooter>
								<HStack justifyContent="space-between" alignItems="center" width="100%">
									<Checkbox
										isDisabled={!isMockCreator}
										isChecked={isMock}
										onChange={() => {
											const newIsMock = !isMock; // Calculate the new value
											setIsMock(newIsMock); // Update local state
											updateABTest({ isMock: newIsMock }); // Use the new value
										}}
									>
										Is Mock
									</Checkbox>
									<HStack spacing={3}>
										<Button variant="ghost" mr={3} onClick={onClose}>
											Close
										</Button>
										<Button
											colorScheme="blue"
											mr={3}
											isLoading={props.isSubmitting}
											isDisabled={!props.isValid || isABTestCompletedOrCancelled}
											type="submit"
										>
											{abTestToUpdate === undefined ? 'Create Experiment' : 'Update Experiment'}
										</Button>
									</HStack>
								</HStack>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}

const handleCreateExperiment = async (store: GlobalStore, abTest: ABTest, toast: any, onClose: () => void) => {
	try {
		//await abTestValidationSchema.validate(values, { abortEarly: false });
		//const payload = createAbTestPayload({ ...values, responseData });

		if (!store.JWTToken || !store.gameId) {
			throw new Error('Invalid user token or game id');
		}

		await createExperimentRequest(store.JWTToken, store.gameId, abTest);
		onClose();
		toast({
			title: 'Experiment created.',
			description: 'Your A/B test experiment has been successfully created.',
			status: 'success',
			duration: 5000,
			isClosable: true,
			position: 'top',
		});
	} catch (error) {
		console.error('Error creating experiment:', error);
		toast({
			title: 'Error creating experiment.',
			description: error instanceof Error ? error.message : String(error),
			status: 'error',
			duration: 5000,
			isClosable: true,
			position: 'top',
		});
	}
};

const handleUpdateExperiment = async (store: GlobalStore, abTest: ABTest, toast: any, onClose: () => void) => {
	try {
		//await abTestValidationSchema.validate(values, { abortEarly: false });
		//const payload = createAbTestPayload({ ...values, responseData });
		if (!store.JWTToken || !store.gameId) {
			throw new Error('Invalid user token or game id');
		}

		if (abTest.targetedVariants && abTest.targetedVariants.length > 0) {
			abTest.isTargeted = true;
			toast({
				title: 'Targeted variants will be updated.',
				description: 'This process may take a while. Please wait for the process to complete.',
				status: 'warning',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
		}

		await updateExperiment(store.JWTToken, store.gameId, abTest);
		onClose();
		toast({
			title: 'Experiment updated.',
			description: 'Your A/B test experiment has been successfully updated.',
			status: 'success',
			duration: 5000,
			isClosable: true,
			position: 'top',
		});
	} catch (error) {
		console.error('Error updating experiment:', error);
		toast({
			title: 'Error updating experiment.',
			description: error instanceof Error ? error.message : String(error),
			status: 'error',
			duration: 5000,
			isClosable: true,
			position: 'top',
		});
	}
};

function createDefaultABTest(isMockCreator: boolean): ABTest {
	return {
		id: 0,
		uid: '',
		name: '',
		description: '',
		identifier: '',
		freezeRemoteConfig: false,
		isTargeted: false,
		dailyUserLimit: 0,
		totalMaximumUserCount: 0,
		creationDate: Date.now(),
		activationDate: null,
		lastUpdated: Date.now(),
		variants: [
			{
				uuid: '',
				name: 'Base Variant',
				values: [],
				isActive: true,
			},
			{
				uuid: '',
				name: 'Variant 1',
				values: [],
				isActive: true,
			},
		],
		state: ABTestState.WaitingToStart,
		allowMultipleABTests: true,
		activationEventName: null,
		filterOperations: [],
		platform: Platform.All,
		userTargeting: UserTargeting.All,
		buildNumber: 0,
		buildNumberFilter: 0,
		version: null,
		versionFilter: 0,
		country: null,
		countryFilter: 0,
		createdBy: 0,
		updatedBy: 0,
		endDate: null,
		isDeleted: false,
		isOverWritten: false,
		isMock: isMockCreator,
		variantFileName: '',
		targetedVariants: null,
	};
}

export default ABTestModal;
