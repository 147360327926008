import React, { useRef, useState, useEffect } from 'react';
import {
	FormControl,
	FormLabel,
	Select,
	HStack,
	Box,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Input,
	FormErrorMessage,
	Text,
	Button,
	useToast,
} from '@chakra-ui/react';

import { Select as ChakraReactSelect } from 'chakra-react-select';
import { Field } from 'formik';
import { ABTest, ABTestVariant } from '@repo/alictus-common/types/ab_test';
import { FilterCondition } from '@repo/alictus-common/enums/filter_condition';
import config from '../../config';
import globalStore from '../../store';
import { Countries } from '@repo/alictus-common/enums/remote_config';
import { DownloadIcon } from '@chakra-ui/icons';
import { ABTestState } from '@repo/alictus-common/enums/ab_test_state';
import Papa from 'papaparse';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { FilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';

const countryList = Countries.map((country) => ({ value: country.code, label: country.name }));
type VariantFile = {
	VARIANT: string;
	PLAYER_ID: string;
};

export function Targeting({
	abTestModal,
	updateAbTestModal,
	isABTestNewlyCreated,
	segmentationData,
}: {
	abTestModal: ABTest;
	updateAbTestModal: (test: Partial<ABTest>) => void;
	isABTestNewlyCreated: boolean;
	segmentationData: PlayerSegment[];
}) {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isFileSelected, setIsFileSelected] = useState(false);
	const [isFileDownloadable, setIsFileDownloadable] = useState(false);
	const [selectFileButtonColor, setSelectFileButtonColor] = useState('blue');
	const [checkVariantsButtonColor, setCheckVariantsButtonColor] = useState('blue');
	const [isABTestCompletedOrCancelled, setIsABTestCompletedOrCancelled] = useState(false);
	const [segmentNames, setSegmentNames] = React.useState<any[]>([]);
	const toast = useToast();
	let store = globalStore();

	useEffect(() => {
		setIsFileDownloadable(abTestModal.variantFileName !== '');
		setIsABTestCompletedOrCancelled(abTestModal.state === ABTestState.Completed || abTestModal.state === ABTestState.Cancelled);
		setSegmentNames(extractSegmentNames(segmentationData));
	}, [abTestModal.variantFileName, abTestModal.state, segmentationData]);

	const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Warn user if they try to upload a file with a different than csv extension
		try {
			const fileName = event.target.files?.[0].name;
			if (!fileName!.endsWith('.csv')) {
				toast({
					title: 'Invalid File Type',
					description: 'Please upload a CSV file',
					status: 'error',
					duration: 5000,
					position: 'top',
					isClosable: true,
				});
				return;
			}

			setIsFileSelected(true);
			setSelectFileButtonColor('green');
		} catch (error) {
			console.error('Error selecting file:', error);
			toast({
				title: 'Error',
				description: 'There was an issue selecting the file.',
				status: 'error',
				duration: 5000,
				position: 'top',
				isClosable: true,
			});
			setSelectFileButtonColor('red');
		}
	};

	const checkVariantNames = async (file: File, abTestVariantList: Array<ABTestVariant>) => {
		return new Promise<{
			areAllFileVariantsInABTestList: boolean;
			variantsNotInABTestList: string[];
			outputList: string[][];
		}>((resolve, reject) => {
			const variantsNotInABTestList: string[] = [];
			const outputList: string[][] = [];

			Papa.parse<VariantFile>(file, {
				header: true,
				delimiter: ',',
				skipEmptyLines: true,
				complete: (results) => {
					try {
						results.data.forEach(({ VARIANT, PLAYER_ID }) => {
							if (
								!PLAYER_ID.trim() ||
								PLAYER_ID.trim() === '' ||
								PLAYER_ID.trim().includes('undefined') ||
								PLAYER_ID.trim().includes('null')
							) {
								return;
							}

							if (!abTestVariantList.some((variant) => variant.name === VARIANT.trim())) {
								if (!variantsNotInABTestList.includes(VARIANT.trim())) {
									variantsNotInABTestList.push(VARIANT.trim());
								}
							}

							const matchedVariant = abTestVariantList.find((variant) => variant.name === VARIANT.trim());
							if (matchedVariant?.isActive) {
								outputList.push([VARIANT.trim(), PLAYER_ID.trim(), matchedVariant.uuid.trim() || '']);
							}
						});

						const areAllFileVariantsInABTestList = variantsNotInABTestList.length === 0;

						resolve({ areAllFileVariantsInABTestList, variantsNotInABTestList, outputList });
					} catch (error) {
						reject(error);
					}
				},
				error: (error) => {
					reject(error);
				},
			});
		});
	};

	const checkVariantsInFile = async (abTest: ABTest) => {
		const variantsInABTest = abTest.variants;
		const file = fileInputRef.current?.files?.[0];

		if (file) {
			try {
				const { areAllFileVariantsInABTestList, variantsNotInABTestList, outputList } = await checkVariantNames(file, variantsInABTest);

				if (!areAllFileVariantsInABTestList) {
					toast({
						title: 'Warning',
						//description: 'There are variants in the file that are not in the A/B test.',
						description: (
							<Box>
								There are variants in the file that are not in the A/B test:
								{variantsNotInABTestList.map((variant) => (
									<Box key={variant}>- {variant}</Box>
								))}
							</Box>
						),
						status: 'warning',
						duration: 5000,
						position: 'top',
						isClosable: true,
					});
					setCheckVariantsButtonColor('red');
					return;
				} else {
					updateAbTestModal({ targetedVariants: outputList });
					setCheckVariantsButtonColor('green');

					toast({
						title: 'Success',
						description: 'All variants in the A/B test are in the file.',
						status: 'success',
						duration: 5000,
						position: 'top',
						isClosable: true,
					});
				}
			} catch (error) {
				console.error('Error checking variants in file:', error);
				toast({
					title: 'Error',
					description: 'There was an issue processing the file.',
					status: 'error',
					duration: 5000,
					position: 'top',
					isClosable: true,
				});
				setCheckVariantsButtonColor('red');
				return;
			}
		}
	};

	const extractSegmentNames = (segments: PlayerSegment[]): any[] => {
		return segments.map((segment) => ({ value: segment.uid, label: segment.name }));
	};

	function getSegmentFilter(): FilterOperation {
		if (abTestModal) {
			const result = abTestModal.filterOperations.find((filter) => filter.Filter === FilterType.Segmentation) as FilterOperation;
			if (result) {
				return result;
			}
		}
		return { Filter: FilterType.Segmentation, Condition: FilterCondition.NoOp, Values: [] };
	}

	type multiSelection = {
		value: string;
		label: string;
	};

	function getSegmentNameFromUid(uid: string): string {
		let segment = segmentationData.find((segment) => segment.uid === uid);
		if (segment) {
			return segment.name;
		}

		return '';
	}

	function getSelectedSegments(): multiSelection[] {
		//get country filter values and generate country selection from countryList
		let segmentFilter = getSegmentFilter();
		let selectedSegments: multiSelection[] = [];
		if (segmentFilter.Values) {
			selectedSegments = segmentFilter.Values.map((segment) => {
				return { value: segment as string, label: getSegmentNameFromUid(segment as string) };
			});
		}
		return selectedSegments;
	}

	function updateSegmentCondition(condition: FilterCondition) {
		console.log('Update Segment Condition', condition);
		let segmentFilter = getSegmentFilter();
		segmentFilter.Condition = condition;
		segmentFilter.Filter = FilterType.Segmentation;

		if (segmentFilter.Values.length === 0 && condition === FilterCondition.NoOp) {
			let updatedFilters = abTestModal.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);

			// If segmentation filter is removed, delete all variant values that have filterOperations.length > 0
			let updatedVariants = abTestModal.variants.map((variant) => {
				let filteredValues = variant.values.filter((value) => {
					// Remove values that were added as segmentation groups (i.e., they have filterOperations)
					return value.filterOperations.length === 0;
				});

				return { ...variant, values: filteredValues };
			});

			updateAbTestModal({
				filterOperations: updatedFilters,
				variants: updatedVariants,
			});

			return;
		}
		if (abTestModal) {
			let updatedFilters = abTestModal.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);
			updateAbTestModal({ filterOperations: [...updatedFilters, segmentFilter] });
		}
	}

	function updateSegmentFilter(selection: multiSelection[]) {
		console.log('Update Segment Filter', selection);

		if (selection.length === 0) {
			// If segmentation filter is removed, delete all variant values that have filterOperations.length > 0
			let updatedVariants = abTestModal.variants.map((variant) => {
				let filteredValues = variant.values.filter((value) => {
					// Remove values that were added as segmentation groups (i.e., they have filterOperations)
					return value.filterOperations.length === 0;
				});

				return { ...variant, values: filteredValues };
			});

			updateAbTestModal({
				filterOperations: [],
				variants: updatedVariants,
			});
			return;
		}

		// Get the updated segmentation filter
		let segmentFilter = getSegmentFilter();
		segmentFilter.Values = selection.map((segment) => segment.value);
		segmentFilter.Filter = FilterType.Segmentation;
		segmentFilter.Condition = FilterCondition.Include;

		if (abTestModal) {
			// Remove the existing segmentation filter before adding the updated one
			let updatedFilters = abTestModal.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);

			// Update all variants to remove deleted segments from their filterOperations
			let updatedVariants = abTestModal.variants.map((variant) => {
				let updatedValues = variant.values.map((value) => {
					// If the value has a segmentation filter, update it
					if (value.filterOperations.some((filter) => filter.Filter === FilterType.Segmentation)) {
						let updatedFilterOperations = value.filterOperations.map((filter) => {
							if (filter.Filter === FilterType.Segmentation) {
								let newValues = filter.Values.filter(
									(val) => segmentFilter.Values.includes(val), // Keep only the segments that still exist
								);
								return { ...filter, Values: newValues };
							}
							return filter;
						});

						return { ...value, filterOperations: updatedFilterOperations };
					}
					return value;
				});

				return { ...variant, values: updatedValues };
			});

			updateAbTestModal({
				filterOperations: [...updatedFilters, segmentFilter],
				variants: updatedVariants,
			});
		}
	}

	return (
		<>
			<Field name="userTargeting">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} isInvalid={form.errors.userTargeting && form.touched.userTargeting}>
						<FormLabel>Select User Targeting</FormLabel>
						<Select
							{...field}
							value={abTestModal.userTargeting}
							onChange={(e) => {
								form.setFieldValue('userTargeting', parseInt(e.target.value));
								updateAbTestModal({ userTargeting: parseInt(e.target.value) });
							}}
						>
							<option value={0}>All</option>
							<option value={1}>Only New</option>
							<option value={2}>Only Existing</option>
						</Select>
						<FormErrorMessage>{form.errors.userTargeting}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="country">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country}>
						<FormLabel>Select Country</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								{...field}
								value={abTestModal.countryFilter}
								width={'200px'}
								onChange={(e: any) => {
									form.setFieldValue('countryFilter', parseInt(e.target.value));
									updateAbTestModal({ countryFilter: parseInt(e.target.value) });
								}}
							>
								<option value={0}>Select Filter</option>
								<option value={7}>Include</option>
								<option value={8}>Exclude</option>
							</Select>
							<Box flex={1} width={'660px'}>
								<ChakraReactSelect
									{...field}
									options={countryList}
									value={abTestModal.country}
									isMulti
									onChange={(e: any) => {
										if (e) {
											form.setFieldValue('country', e as { value: string; label: string }[]);
											updateAbTestModal({ country: e as { value: string; label: string }[] });
										}
										//field.onChange(e);
									}}
								/>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.country}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="version">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.version}>
						<FormLabel>Select Version</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								value={abTestModal.versionFilter}
								width={'200px'}
								onChange={(e: any) => {
									if (e && e.target.value) {
										form.setFieldValue('versionFilter', parseInt(e.target.value));
										updateAbTestModal({ versionFilter: parseInt(e.target.value) });
										//field.onChange(e);
									}
								}}
							>
								{Object.keys(FilterCondition)
									.filter((key) => isNaN(Number(key))) // Only include string keys
									.map((key) => (
										<option
											key={FilterCondition[key as keyof typeof FilterCondition]}
											value={FilterCondition[key as keyof typeof FilterCondition]}
										>
											{key}
										</option>
									))}
							</Select>
							<Box flex={1}>
								<Input
									{...field}
									value={abTestModal.version}
									onChange={(e) => {
										if (e) {
											form.setFieldValue('version', e.target.value);
											updateAbTestModal({ version: e.target.value });
											//field.onChange(e);
										}
									}}
									placeholder="Filter Value"
								/>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.version}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="buildNumber">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.buildNumber}>
						<FormLabel>Select Build Number</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								value={abTestModal.buildNumberFilter.toString() || 0}
								width={'200px'}
								onChange={(e: any) => {
									if (e) {
										form.setFieldValue('buildNumberFilter', parseInt(e.target.value));
										updateAbTestModal({ buildNumberFilter: parseInt(e.target.value) });
									}
								}}
							>
								{Object.keys(FilterCondition)
									.filter((key) => isNaN(Number(key))) // Only include string keys
									.map((key) => (
										<option
											key={FilterCondition[key as keyof typeof FilterCondition]}
											value={FilterCondition[key as keyof typeof FilterCondition]}
										>
											{key}
										</option>
									))}
							</Select>
							<Box flex={1}>
								<NumberInput
									{...field}
									value={abTestModal.buildNumber.toString() || 0}
									onChange={(e: any) => {
										if (e) {
											form.setFieldValue('buildNumber', parseInt(e));
											updateAbTestModal({ buildNumber: parseInt(e) });
											//field.onChange(e);
										}
									}}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.buildNumber}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="segmentation">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
						<FormLabel>Select Segment</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							{/*<Select
								value={getSegmentFilter().Condition}
								width={'200px'}
								onChange={(e: any) => {
									updateSegmentCondition(parseInt(e.target.value));
									field.onChange(e);
								}}
							>
								<option value={FilterCondition.NoOp}>Select Filter</option>
								<option value={FilterCondition.Include}>Include</option>
								<option value={FilterCondition.Exclude}>Exclude</option>
							</Select>*/}
							<Box flex={1}>
								<ChakraReactSelect
									{...field}
									options={segmentNames}
									value={getSelectedSegments()}
									isMulti
									menuPlacement="top"
									onChange={(e: any) => {
										if (e) {
											updateSegmentFilter(e as multiSelection[]);
										}
									}}
								/>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.segment}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="variantFileSelectionBox">
				{({ form }: { field: any; form: any }) => (
					<FormControl mt={4} isInvalid={form.errors.variantFileSelectionBox} isDisabled={isABTestNewlyCreated}>
						<FormLabel>Upload Variant File</FormLabel>
						<HStack spacing={4}>
							<Box flex={1}>
								{abTestModal.variantFileName ? (
									<Text>Last Uploaded File: {abTestModal.variantFileName}</Text>
								) : (
									<Text>No file uploaded yet</Text>
								)}
							</Box>
							<Button
								colorScheme={selectFileButtonColor}
								onClick={() => fileInputRef.current?.click()}
								isDisabled={isABTestNewlyCreated || isABTestCompletedOrCancelled}
							>
								Select File
							</Button>
							<Button colorScheme={checkVariantsButtonColor} onClick={() => checkVariantsInFile(abTestModal)} isDisabled={!isFileSelected}>
								Check Variants
							</Button>
							<Button
								colorScheme="green"
								onClick={async () => {
									try {
										// Construct the URL for the file
										const url = `${config.API_ENDPOINT}/game/com.ck.tripletiles/ab_test/variant_file/${abTestModal.variantFileName}`;

										// Fetch the file from R2
										const response = await fetch(url, { headers: { Authorization: `Bearer ${store.JWTToken}` } });

										if (!response.ok) {
											throw new Error('Failed to download the file');
										}

										// Create a Blob from the file data
										const blob = await response.blob();

										// Create an object URL for the Blob
										const blobUrl = window.URL.createObjectURL(blob);

										// Create a temporary anchor element to trigger the download
										const link = document.createElement('a');
										link.href = blobUrl;
										link.download = abTestModal.variantFileName!; // Use the file name from the API or a default name
										link.click();

										// Clean up the object URL
										window.URL.revokeObjectURL(blobUrl);
									} catch (error) {
										console.error('Error downloading file:', error);
									}
								}}
								isDisabled={!isFileDownloadable}
							>
								<DownloadIcon />
							</Button>
						</HStack>
						<input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelection} />
						<FormErrorMessage>{form.errors.variantFileSelectionBox}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default Targeting;
