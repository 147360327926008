import React from 'react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';
import { Spinner, useToast } from '@chakra-ui/react';
import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, HStack, VStack } from '@chakra-ui/react';

export function DeleteABTestModal({
	abTestName,
	abTestId,
	disclosure,
}: {
	abTestName: string;
	abTestId: number;
	disclosure: { isOpen: boolean; onClose: () => void };
}) {
	const [isDeleting, setIsDeleting] = React.useState(false);
	let globalData = globalStore();
	const toast = useToast();

	function deleteABTestValue(abTestId: number) {
		setIsDeleting(true);
		let url = `${config.API_ENDPOINT}/game/${globalData.gameId}/ab_test/${abTestId}`;
		axios
			.delete(url, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } })
			.then((response) => {
				if (response.status === 200) {
					console.log(response);
					toast({
						title: 'A/B Test deleted successfully',
						status: 'success',
						duration: 5000,
						isClosable: true,
						position: 'top',
					});
					disclosure.onClose();
					setIsDeleting(false);
				}
			})
			.catch((error) => {
				toast({
					title: 'Error deleting A/B Test',
					description: error.response.data.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top',
				});
				console.error(error);
				setIsDeleting(false);
			});
	}

	return (
		<Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete A/B Test</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack>
						<Box mb={'15px'}>Are you sure you want to delete "{abTestName}" ?</Box>
						<HStack>
							<Button variant="outline" mr={3} onClick={disclosure.onClose}>
								Cancel
							</Button>
							{isDeleting ? (
								<Spinner />
							) : (
								<Button
									variant="outline"
									bgColor={'red.400'}
									mr={3}
									onClick={() => {
										if (globalData.JWTToken) {
											deleteABTestValue(abTestId);
										} else {
											console.error('JWTToken is null');
										}
									}}
								>
									Proceed
								</Button>
							)}
						</HStack>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default DeleteABTestModal;
