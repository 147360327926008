import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import globalStore, { GlobalStore } from '../../store';
import { RemoteConfigValue } from '@repo/alictus-common/types';

export function useRemoteConfigData(environment: string) {
	let globalData = globalStore();

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;
		let isActive = true; // Flag to break the loop when unmounted

		// This is added to filter the remote config values based on environment during initial load
		let existingRemoteConfigValues = globalData.remoteConfigValues;
		if (environment === 'development') {
			existingRemoteConfigValues = existingRemoteConfigValues.filter((rcValue) => rcValue.isTest);
		} else {
			existingRemoteConfigValues = existingRemoteConfigValues.filter((rcValue) => !rcValue.isTest);
		}
		globalData.setRemoteConfigValues(existingRemoteConfigValues);

		const fetchDataAsync = async () => {
			console.log('Fetching Remote Config Data');
			await streamRcData(globalData, environment, signal, () => isActive);
		};

		fetchDataAsync().then(() => {
			console.log('Remote Config Data Fetched');
		});

		return () => {
			isActive = false; // Stops the loop
			controller.abort(); // Aborts any ongoing request
		};
	}, []);
}

async function streamRcData(globalData: GlobalStore, environment: string, signal: AbortSignal, isActive: () => boolean) {
	let lastUpdated = 0;

	try {
		while (isActive()) {
			// Keep fetching only if active
			let bearerToken = 'Bearer ' + globalData.JWTToken;
			let url = `${config.CLOUDFLARE_LOCAL_ENDPOINT}/game/${globalData.gameId}/rc_package/stream/${lastUpdated}/${environment}`;

			const response = await axios.get(url, {
				headers: { Authorization: bearerToken },
				signal, // Attach the signal
			});

			let incomingRemoteConfigValues = response.data as RemoteConfigValue[];
			incomingRemoteConfigValues = incomingRemoteConfigValues.filter(
				(rcValue) => globalData.user!.roles.includes('MOCK_CREATOR') || !rcValue.isMock,
			);
			incomingRemoteConfigValues = incomingRemoteConfigValues.filter((rcValue) => rcValue.abTestUid === null);

			// Filter incoming data based on environment, if environment is development, then show only isTest = true
			if (environment === 'development') {
				incomingRemoteConfigValues = incomingRemoteConfigValues.filter((rcValue) => rcValue.isTest);
			} else {
				incomingRemoteConfigValues = incomingRemoteConfigValues.filter((rcValue) => !rcValue.isTest);
			}

			if (incomingRemoteConfigValues) {
				globalData.setRemoteConfigValues(incomingRemoteConfigValues);
			}

			await new Promise((resolve) => setTimeout(resolve, 5000));
		}
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log('Fetch request was canceled:', error.message);
		} else {
			console.error('Error fetching remote config data:', error);
		}
	}
}
