import React, { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Input,
	Tooltip,
	TableContainer,
	useDisclosure,
} from '@chakra-ui/react';
import ABTestModal from '../add_ab_test_modal';
import config from '../../config';
import { FaTrashAlt } from 'react-icons/fa';
import { Icon, ViewIcon, SearchIcon } from '@chakra-ui/icons';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import axios from 'axios';
import { formatTimestamp } from '../../utility';
import { ABTest, ABTestStreamView } from '@repo/alictus-common/types/ab_test';
import { ABTestState } from '@repo/alictus-common/enums/ab_test_state';
import { GlobalStore } from '../../store';
import { ViewFilter } from '../ab_test_view_filter';

function AbTestByteToString(value: any) {
	if (value === 0) return 'Waiting to Start';
	if (value === 1) return 'Running';
	if (value === 2) return 'Completed';
	if (value === 3) return 'No New Users';
	if (value === 4) return 'Cancelled';
	if (value === 5) return 'Paused for Today';
	if (value === 6) return 'Target User Count Reached';
}

interface ABTestTableProps {
	globalData: GlobalStore;
	viewFilter: ViewFilter;
	remoteAbTests: ABTestStreamView[];
	setRemoteAbTests: React.Dispatch<React.SetStateAction<ABTestStreamView[]>>;
	loading: boolean;
	deleteABTestDisclosure: any;
	setDeleteABTestData: (data: any) => void;
	isAdmin: boolean;
}

function ABTestTable({
	globalData,
	viewFilter,
	remoteAbTests,
	setRemoteAbTests,
	loading,
	deleteABTestDisclosure,
	setDeleteABTestData,
	isAdmin,
}: ABTestTableProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedTest, setSelectedTest] = useState<ABTest | null>(null);
	const [sortField, setSortField] = useState<'name'>('name');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [searchQuery, setSearchQuery] = useState(''); // State for search query
	const [isSearchActive, setIsSearchActive] = useState(false);

	const openModal = (test: ABTest) => {
		setSelectedTest(test);
		onOpen();
	};

	const fetchSelectedABTestData = async (abTestID: number): Promise<ABTest> => {
		// Fetch the selected AB test data
		let bearerToken = 'Bearer ' + globalData.JWTToken;
		let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/ab_test/${abTestID}`;
		const response = await axios.get(url, { headers: { Authorization: bearerToken } });
		let selectedABTestData = response.data as ABTest;

		return selectedABTestData;
	};

	const updateAbState = async (abTest: ABTest, state: ABTestState) => {
		let fetchedABTestData = await fetchSelectedABTestData(abTest.id);
		fetchedABTestData.state = state;

		let url = config.API_ENDPOINT + '/game/' + globalData.gameId + '/ab_test/' + abTest.id;
		abTest.state = state;
		axios.put(url, fetchedABTestData, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
			console.log('AB Test updated successfully:', response.data);
			setRemoteAbTests(
				remoteAbTests.map((abTestStreamView) => {
					if (abTestStreamView.abTest.id === abTest.id) {
						abTestStreamView.abTest = abTest;
					}
					return abTestStreamView;
				}),
			);
		});
	};

	const handleSort = (field: 'name') => {
		if (sortField === field) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('asc');
		}
	};

	const filteredData = (remoteAbTests || []).filter((item) => item.abTest.name.toLowerCase().includes(searchQuery.toLowerCase()));

	const sortedData = filteredData.sort((a, b) => {
		let result = 0;
		if (sortField === 'name') {
			result = a.abTest.name.localeCompare(b.abTest.name);
		}
		return sortDirection === 'asc' ? result : -result;
	});

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<Spinner size="xl" />
			</Box>
		);
	}

	return (
		<TableContainer width="100%">
			<Table variant="simple" size="md" border="0px" width="100%" style={{ tableLayout: 'fixed' }}>
				<Thead>
					<Tr>
						<Th width="11%" cursor="pointer">
							<Flex align="center" gap="15px" height="20px">
								<Tooltip label="Search by Name" placement="top">
									<Icon
										as={SearchIcon}
										boxSize={4}
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											setIsSearchActive(!isSearchActive);
										}}
									/>
								</Tooltip>
								{isSearchActive && (
									<Input
										size="sm"
										placeholder="Search..."
										value={searchQuery}
										onChange={(e) => setSearchQuery(e.target.value)}
										autoFocus
										width="100%"
									/>
								)}
								{!isSearchActive && (
									<span onClick={() => handleSort('name')}>Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</span>
								)}
							</Flex>
						</Th>
						<Th width="11%">Description</Th>
						<Th width="10%">Id</Th>
						<Th width="8%">Platform</Th>
						<Th width="8%">Activation Date</Th>
						<Th width="8%">Total Users</Th>
						<Th width="8%">Daily Users</Th>
						<Th width="10%">End Date</Th>
						<Th width="10%">State</Th>
						<Th width="4%" textAlign="center">
							View
						</Th>
						{isAdmin && (
							<Th width="6%" textAlign="center">
								Delete
							</Th>
						)}
						<Th width="6%">Action</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedData.map(
						({ abTest: test, stats }: ABTestStreamView) =>
							viewFilter.canDisplay(test) && (
								<Tr key={test.id}>
									<Td
										width="11%"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{test.name}
									</Td>
									<Td
										width="11%"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{test.description}
									</Td>
									<Td
										width="10%"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{test.uid}
									</Td>
									<Td width="8%">{test.platform}</Td>
									<Td width="8%">{formatTimestamp(test.activationDate)}</Td>
									<Td width="8%">{stats === null ? 0 : stats.totalAssignmentCount}</Td>
									<Td width="8%">{stats === null ? 0 : stats.dailyAssignmentCount}</Td>
									<Td width="10%">{formatTimestamp(test.endDate)}</Td>
									<Td width="10%">{AbTestByteToString(test.state)}</Td>
									<Td width="4%" textAlign="center">
										<Button
											onClick={() => openModal(test)}
											leftIcon={<Icon as={ViewIcon} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
											variant="simple"
										/>
									</Td>
									{isAdmin && (
										<Td textAlign="center" verticalAlign="middle" width="6%">
											<Button
												isDisabled={!isAdmin}
												onClick={() => {
													setDeleteABTestData(test);
													deleteABTestDisclosure.onOpen();
												}}
												leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
												variant="simple"
											/>
										</Td>
									)}
									<Td width="6%">
										{test.state !== ABTestState.Cancelled && test.state !== ABTestState.Completed ? (
											<Menu>
												<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
													<IconButton
														onClick={onOpen}
														aria-label="Add"
														icon={<PiDotsThreeOutlineVerticalFill color={'gray.500'} />}
														backgroundColor={'transparent'}
														_hover={{ bg: 'transparent', color: 'white' }}
													/>
												</MenuButton>
												<MenuList>
													{test.state === ABTestState.WaitingToStart && (
														<MenuItem onClick={() => updateAbState(test, ABTestState.Running)}>Start Test</MenuItem>
													)}
													{(test.state === ABTestState.Running ||
														test.state === ABTestState.PausedForToday ||
														test.state === ABTestState.NoNewUser ||
														test.state === ABTestState.WaitingToStart) && (
														<MenuItem onClick={() => updateAbState(test, ABTestState.Cancelled)}>Cancel Test</MenuItem>
													)}
													{test.state === ABTestState.Running && (
														<MenuItem onClick={() => updateAbState(test, ABTestState.NoNewUser)}>Stop Acquiring Users</MenuItem>
													)}
													{(test.state === ABTestState.Running ||
														test.state === ABTestState.NoNewUser ||
														test.state === ABTestState.PausedForToday ||
														test.state === ABTestState.TargetUserCountReached) && (
														<MenuItem onClick={() => updateAbState(test, ABTestState.Completed)}>Mark as Complete</MenuItem>
													)}
												</MenuList>
											</Menu>
										) : test.state === ABTestState.Cancelled ? (
											'Cancelled'
										) : (
											'Completed'
										)}
									</Td>
								</Tr>
							),
					)}
				</Tbody>
			</Table>
			{isOpen && <ABTestModal title={'Edit AB Test'} isOpen={isOpen} onClose={onClose} abTestToUpdate={selectedTest!} />}
		</TableContainer>
	);
}

export default ABTestTable;
