import { Box, Grid, GridItem, HStack, Spinner, VStack } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import React, { useEffect, useState } from 'react';
import RemoteConfigVariableModal from './edit_remote_variable_modal';
import DeleteRCKeyModal from './delete_rc_key_modal';
import { ViewFilter } from './view_filter';
import globalStore from '../store';
import { RemoteConfigHeader } from './components/remote_config_header';
import { RemoteConfigTable } from './components/remote_config_table';
import { useRemoteConfigData } from './hooks/use_remote_config_data';
import { RemoteConfigValue } from '@repo/alictus-common/types/remote_config';
import config from '../config';
import axios from 'axios';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

function RemoteConfigData({ environment, header }: { environment: string; header: string }) {
	useRemoteConfigData(environment);
	return <DisplayRemoteConfigDatas environment={environment} header={header} />;
}

function DisplayRemoteConfigDatas({ environment, header }: { environment: string; header: string }) {
	return (
		<VStack spacing={10} align="stretch">
			<DisplayRemoteConfigData environment={environment} header={header} />
		</VStack>
	);
}

function DisplayRemoteConfigData({ environment, header }: { environment: string; header: string }) {
	let globalData = globalStore();
	const addVariableDisclosure = useDisclosure();
	const editRCVariableDisclosure = useDisclosure();
	const deleteRCKeyDisclosure = useDisclosure();
	const [rcVariableData, setRCVariableData] = useState<any>();
	const [deleteRCKeyData, setDeleteRCKeyData] = useState<any>(null);
	const [viewFilter, setViewFilter] = useState(new ViewFilter());
	const [segmentationData, setSegmentationData] = useState<any>();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchSegmentationData = async () => {
			try {
				let bearerToken = 'Bearer ' + globalData.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let playerSegments = response.data as PlayerSegment[];

				setSegmentationData(playerSegments);
				viewFilter.setSegmentationData(playerSegments);
			} catch (error) {
				console.error('Error fetching segmentation data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchSegmentationData();
	}, []);

	const multiFunction = (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => {
		setRCVariableData(remoteConfigValue);
		disc.onOpen();
	};

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<Spinner size="xl" />
			</Box>
		);
	}

	return (
		<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
			<RemoteConfigVariableModal disclosure={editRCVariableDisclosure} remoteData={rcVariableData} environment={environment} />
			<RemoteConfigVariableModal disclosure={addVariableDisclosure} remoteData={null} environment={environment} />

			{deleteRCKeyDisclosure.isOpen && deleteRCKeyData && (
				<DeleteRCKeyModal disclosure={deleteRCKeyDisclosure} rcKey={deleteRCKeyData.key} rcValueId={deleteRCKeyData.id} />
			)}

			<VStack spacing={1} align="stretch">
				<HStack width={'100%'}>
					<RemoteConfigHeader
						addVariableDisclosure={addVariableDisclosure}
						viewFilter={viewFilter}
						segmentationData={segmentationData}
						setViewFilter={setViewFilter}
						environment={environment}
						header={header}
					/>
				</HStack>
				<RemoteConfigTable
					globalData={globalData}
					viewFilter={viewFilter}
					editRCVariableDisclosure={editRCVariableDisclosure}
					deleteRCKeyDisclosure={deleteRCKeyDisclosure}
					setDeleteRCKeyData={setDeleteRCKeyData}
					multiFunction={multiFunction}
					segmentationData={segmentationData}
					environment={environment}
				/>
			</VStack>
		</Box>
	);
}

function RemoteConfig({ environment, header }: { environment: string; header: string }) {
	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={5} padding={'10px'}></GridItem>
			<GridItem paddingLeft={'5px'} paddingRight={'5px'} colSpan={5}>
				<RemoteConfigData environment={environment} header={header} />
			</GridItem>
		</Grid>
	);
}

export default RemoteConfig;
