import { z } from 'zod';
import { Platform } from '../types';
import { UserTargeting } from '../types';
import { RCType } from '../types';
import { FilterCondition, FilterType } from '../types';

const abTestValidationSchema = z
	.object({
		name: z.string().min(3, 'Experiment Name is required'),
		description: z.string().min(3, 'Experiment Description is required'),
		platform: z.nativeEnum(Platform, { message: 'Platform is required' }),
		userTargeting: z.nativeEnum(UserTargeting, { message: 'User targeting is required' }),
		countryFilter: z.number().optional(),
		country: z
			.array(
				z.object({
					value: z.string().min(1, 'Country value is required'),
					label: z.string().min(1, 'Country label is required'),
				}),
			)
			.nullable()
			.optional(),

		versionFilter: z.number().optional(),
		version: z.string().nullable().optional(),
		buildNumber: z.number().nonnegative().min(0, { message: 'Build number must be non-negative' }).optional(),
		buildNumberFilter: z.number().optional(),
		variants: z
			.array(
				z.object({
					name: z.string().min(1, 'Variant name is required'),
					values: z
						.array(
							z.object({
								key: z.string().min(1, 'RC key is required'),
								value: z.any().refine((val) => val !== undefined, {
									message: 'RC value is required',
								}),
								type: z.nativeEnum(RCType, { message: 'RC type is required' }),
							}),
						)
						//.optional(),
						.nonempty(),
				}),
			)
			//.optional(),
			.min(2, { message: 'At least two variants are required' }),

		state: z
			.number()
			.nonnegative()
			.refine((val) => val !== undefined, {
				message: 'State is required',
			}),
		dailyUserLimit: z.number().nonnegative().min(1, { message: 'Daily user limit must be at least 1' }),
		totalMaximumUserCount: z.number().nonnegative().min(1, { message: 'Total maximum user count must be at least 1' }),
	})
	.superRefine((data, ctx) => {
		if (
			(data.countryFilter === 7 || data.countryFilter === 8) &&
			(data.country === null || data.country === undefined || data.country.length === 0)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country filter is selected, at least one country must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.countryFilter === 0 && data.country && data.country.length > 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country is selected, a country filter must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.version && data.version.length > 0) {
			let splitted_version = data.version.split('.');
			if (
				splitted_version.length !== 3 ||
				splitted_version.some((v) => !/^\d+$/.test(v)) ||
				splitted_version.some((v) => parseInt(v) < 0)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Version must be in the format x.y.z where x, y, z are nonnegative integers',
					path: ['version'],
				});
			}

			if (data.versionFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If version is entered, a version filter must be selected',
					path: ['version'],
				});
			}
		}

		if (data.versionFilter !== 0 && (!data.version || data.version.length === 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If version filter is selected, a version must be entered',
				path: ['version'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.buildNumber) {
			if (isNaN(data.buildNumber) || data.buildNumber < 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Build number must be a nonnegative integer',
					path: ['buildNumber'],
				});
			}

			if (data.buildNumberFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If build number is entered, a build number filter must be selected',
					path: ['buildNumber'],
				});
			}
		}

		if (data.buildNumberFilter !== 0 && (!data.buildNumber || data.buildNumber <= 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If build number filter is selected, a build number must be entered',
				path: ['buildNumber'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.dailyUserLimit && data.totalMaximumUserCount && data.dailyUserLimit > data.totalMaximumUserCount) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Daily user limit must be less than or equal to total maximum user count',
				path: ['dailyUserLimit'],
			});
		}
	})
	.superRefine((data, ctx) => {
		try {
			if (data.variants && data.variants.length > 0) {
				let variantList = data.variants;

				for (let i = 0; i < variantList.length; i++) {
					let variant = variantList[i];
					let variantValues = variant.values;

					if (variantValues && variantValues.length > 0) {
						for (let j = 0; j < variantValues.length; j++) {
							let value = variantValues[j];
							let valueType = value.type;
							if (value.value && value.value.length > 0) {
								if (valueType === RCType.Boolean) {
									if (value.value !== 'true' && value.value !== 'false') {
										ctx.addIssue({
											code: z.ZodIssueCode.custom,
											message: 'Value must be true or false for RC Key: ' + value.key,
										});
									}
								} else if (valueType === RCType.Integer) {
									if (!/^\d+$/.test(value.value)) {
										ctx.addIssue({
											code: z.ZodIssueCode.custom,
											message: 'Value must be an integer for RC Key: ' + value.key,
										});
									}
								} else if (valueType === RCType.Float) {
									if (!/^\d+(\.\d+)?$/.test(value.value)) {
										ctx.addIssue({
											code: z.ZodIssueCode.custom,
											message: 'Value must be a float for RC Key: ' + value.key,
										});
									}
								} else if (valueType === RCType.String) {
									if (value.value.startsWith('{')) {
										try {
											JSON.parse(value.value);

											// Check minified size of JSON is bigger than 2000 KB
											const minifiedSizeBytes = new TextEncoder().encode(JSON.stringify(JSON.parse(value.value), null, 0)).length;
											if (minifiedSizeBytes > 2000 * 1024) {
												ctx.addIssue({
													code: z.ZodIssueCode.custom,
													message: 'Value JSON string is too large for RC Key: ' + value.key,
												});
											}
										} catch (e) {
											ctx.addIssue({
												code: z.ZodIssueCode.custom,
												message: 'Value must be a valid JSON string for RC Key: ' + value.key,
											});
										}
									}
								}
							}
						}
					}
				}
			}
		} catch (error) {
			console.error('Error validating experiment:', error);
		}
	});

const abTestSegmentedValidationSchema = z
	.object({
		name: z.string().min(3, 'Experiment Name is required and must be at least 3 characters'),
		description: z.string().min(3, 'Experiment Description is required and must be at least 3 characters'),
		platform: z.nativeEnum(Platform, { message: 'Platform is required' }),
		userTargeting: z.nativeEnum(UserTargeting, { message: 'User targeting is required' }),
		countryFilter: z.number().optional(),
		filterOperations: z.array(
			z.object({
				Condition: z.nativeEnum(FilterCondition, { message: 'Filter condition is required' }),
				Filter: z.nativeEnum(FilterType, { message: 'Filter type is required' }),
				Values: z.array(z.string().min(1, 'Filter value is required')).nonempty(),
			}),
		),
		country: z
			.array(
				z.object({
					value: z.string().min(1, 'Country value is required'),
					label: z.string().min(1, 'Country label is required'),
				}),
			)
			.nullable()
			.optional(),

		versionFilter: z.number().optional(),
		version: z.string().nullable().optional(),
		buildNumber: z.number().nonnegative().min(0, { message: 'Build number must be non-negative' }).optional(),
		buildNumberFilter: z.number().optional(),
		variants: z
			.array(
				z.object({
					name: z.string().min(1, 'Variant name is required'),
					values: z
						.array(
							z.object({
								key: z.string(),
								value: z.any().refine((val) => val !== undefined, {
									message: 'Segmentation value is required',
								}),

								filterOperations: z.array(
									z.object({
										Condition: z.nativeEnum(FilterCondition, { message: 'Filter condition is required' }),
										Filter: z.nativeEnum(FilterType, { message: 'Filter type is required' }),
										Values: z.array(z.string().min(1, 'Filter value is required')).nonempty(),
									}),
								),
								type: z.nativeEnum(RCType, { message: 'RC type is required' }),
							}),
						)
						//.optional(),
						.nonempty(),
				}),
			)
			//.optional(),
			.min(2, { message: 'At least two variants are required' }),
		state: z
			.number()
			.nonnegative()
			.refine((val) => val !== undefined, {
				message: 'State is required',
			}),
		dailyUserLimit: z.number().nonnegative().min(1, { message: 'Daily user limit must be at least 1' }),
		totalMaximumUserCount: z.number().nonnegative().min(1, { message: 'Total maximum user count must be at least 1' }),
	})
	.superRefine((data, ctx) => {
		if (
			(data.countryFilter === 7 || data.countryFilter === 8) &&
			(data.country === null || data.country === undefined || data.country.length === 0)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country filter is selected, at least one country must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.countryFilter === 0 && data.country && data.country.length > 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country is selected, a country filter must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.version && data.version.length > 0) {
			let splitted_version = data.version.split('.');
			if (
				splitted_version.length !== 3 ||
				splitted_version.some((v) => !/^\d+$/.test(v)) ||
				splitted_version.some((v) => parseInt(v) < 0)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Version must be in the format x.y.z where x, y, z are nonnegative integers',
					path: ['version'],
				});
			}

			if (data.versionFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If version is entered, a version filter must be selected',
					path: ['version'],
				});
			}
		}

		if (data.versionFilter !== 0 && (!data.version || data.version.length === 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If version filter is selected, a version must be entered',
				path: ['version'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.buildNumber) {
			if (isNaN(data.buildNumber) || data.buildNumber < 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Build number must be a nonnegative integer',
					path: ['buildNumber'],
				});
			}

			if (data.buildNumberFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If build number is entered, a build number filter must be selected',
					path: ['buildNumber'],
				});
			}
		}

		if (data.buildNumberFilter !== 0 && (!data.buildNumber || data.buildNumber <= 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If build number filter is selected, a build number must be entered',
				path: ['buildNumber'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.dailyUserLimit && data.totalMaximumUserCount && data.dailyUserLimit > data.totalMaximumUserCount) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Daily user limit must be less than or equal to total maximum user count',
				path: ['dailyUserLimit'],
			});
		}
	})
	.superRefine((data, ctx) => {
		try {
			if (data.variants && data.variants.length > 0) {
				for (let variant of data.variants) {
					let variantValues = variant.values;

					if (variantValues && variantValues.length > 0) {
						for (let j = 0; j < variantValues.length; j++) {
							let value = variantValues[j];
							let valueType = value.type;
							let variantFilterOperations = value.filterOperations;

							if (variantFilterOperations && variantFilterOperations.length > 0) {
								if (value.value && value.value.length > 0) {
									if (valueType === RCType.Boolean) {
										if (value.value !== 'true' && value.value !== 'false') {
											ctx.addIssue({
												code: z.ZodIssueCode.custom,
												message: 'Value must be true or false for RC Key: ' + value.key,
											});
										}
									} else if (valueType === RCType.Integer) {
										if (!/^\d+$/.test(value.value)) {
											ctx.addIssue({
												code: z.ZodIssueCode.custom,
												message: 'Value must be an integer for RC Key: ' + value.key,
											});
										}
									} else if (valueType === RCType.Float) {
										if (!/^\d+(\.\d+)?$/.test(value.value)) {
											ctx.addIssue({
												code: z.ZodIssueCode.custom,
												message: 'Value must be a float for RC Key: ' + value.key,
											});
										}
									} else if (valueType === RCType.String) {
										if (value.value.startsWith('{')) {
											try {
												JSON.parse(value.value);

												// Check minified size of JSON is bigger than 2000 KB
												const minifiedSizeBytes = new TextEncoder().encode(JSON.stringify(JSON.parse(value.value), null, 0)).length;
												if (minifiedSizeBytes > 2000 * 1024) {
													ctx.addIssue({
														code: z.ZodIssueCode.custom,
														message: 'Value JSON string is too large for RC Key: ' + value.key,
													});
												}
											} catch (e) {
												ctx.addIssue({
													code: z.ZodIssueCode.custom,
													message: 'Value must be a valid JSON string for RC Key: ' + value.key,
												});
											}
										}
									}
								}
							}
						}
					}
				}

				// Check values' filter operations arrays, there shouldn't be any filterOperations.Values array both contains exact same values
				const variantValueGroupsAccordingToKey = data.variants[0].values.map((v) => v.key);
				const variantValueGroupsSet = new Set(variantValueGroupsAccordingToKey);

				variantValueGroupsSet.forEach((key) => {
					const filterOperationsListForValue = data.variants[0].values.filter((v) => v.key === key).map((v) => v.filterOperations);

					const filteredFilterOperationsListForValue = filterOperationsListForValue.filter((v) => v !== undefined && v.length > 0);
					if (filteredFilterOperationsListForValue.length > 1) {
						const filterOperationsValuesSet = new Set(filteredFilterOperationsListForValue.map((v) => JSON.stringify(v)));

						if (filterOperationsValuesSet.size !== filteredFilterOperationsListForValue.length) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								message: 'There are duplicate segmentation groups for variant: ' + data.variants[0].name,
							});
						}
					}
				});
			}
		} catch (error) {
			console.error('Error validating experiment:', error);
		}
	});

export { abTestSegmentedValidationSchema, abTestValidationSchema };
