import React from 'react';
import {
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Select,
	Stack,
	Textarea,
	NumberInput,
	NumberInputField,
	useToast,
	VStack,
} from '@chakra-ui/react';

import { Select as ChakraReactSelect } from 'chakra-react-select';
import { LuTestTube2 } from 'react-icons/lu';
import { FaTrashAlt, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Field, FieldArray } from 'formik';
import { ABTestVariant } from '@repo/alictus-common/types/ab_test';
import { RemoteConfigValue } from '@repo/alictus-common/types/remote_config';
import { FilterCondition, Platform } from '@repo/alictus-common/enums/platform';
import { RCType } from '@repo/alictus-common/enums/remote_config';
import { ABTest } from '@repo/alictus-common/types/ab_test';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { FilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';

export function SegmentedVariants({
	abTestModal,
	updateAbTestModal,
	rcValues,
	isABTestNewlyCreated,
	segmentationData,
}: {
	abTestModal: ABTest;
	updateAbTestModal: (test: Partial<ABTest>) => void;
	rcValues: Array<RemoteConfigValue>;
	isABTestNewlyCreated: boolean;
	segmentationData: PlayerSegment[];
}) {
	//const { abTestModal, updateAbTestModal, updateAbTestModalVariant, deleteABTestModalVariant, updateAbTestModalVariantRCValue } = dataStore;
	const toast = useToast();

	if (!abTestModal) {
		return null;
	}

	const addVariableToVariants = (variableRCValue: string) => {
		if (variableRCValue === '') {
			console.error('variableKey is empty');
			return;
		}
		if (abTestModal === null) {
			console.error('abTestModal is null');
			return;
		}

		let variableRCValueObj: RemoteConfigValue = JSON.parse(variableRCValue);

		const newValue: RemoteConfigValue = {
			abTestUid: '',
			description: '',
			filterOperations: [{ Filter: FilterType.Segmentation, Condition: FilterCondition.Include, Values: [] }],
			id: 0,
			isActive: true,
			isDeleted: false,
			isPersonal: false,
			isOverWritten: false,
			isTest: false,
			lastUpdated: 0,
			platform: Platform.All,
			type: variableRCValueObj.type,
			userId: 0,
			variantId: null,
			key: variableRCValueObj.key,
			uid: '',
			value: '',
			lastUpdater: '',
			isMock: false,
			hash: '',
		};

		for (let i = 0; i < abTestModal.variants.length; i++) {
			const variantValues = abTestModal.variants[i].values;
			for (let j = 0; j < variantValues.length; j++) {
				if (variableRCValueObj.key === variantValues[j].key) {
					console.log('Variable already exists');
					return;
				}
			}
		}

		for (let i = 0; i < abTestModal.variants.length; i++) {
			if (abTestModal.variants[i].isActive) {
				abTestModal.variants[i].values.push(newValue);
			}
		}

		const newVariants = [...abTestModal.variants];
		updateAbTestModal({ variants: newVariants });
	};

	const deleteVariableFromVariants = (variableKey: string) => {
		if (abTestModal === null) {
			console.error('abTestModal is null');
			return;
		}

		for (let i = 0; i < abTestModal.variants.length; i++) {
			const variantValues = abTestModal.variants[i].values;
			abTestModal.variants[i].values = variantValues.filter((value) => value.key !== variableKey);
		}

		const newVariants = [...abTestModal.variants];
		updateAbTestModal({ variants: newVariants });
	};

	const addToVariants = () => {
		// Ensure deep copy of values to avoid unintended mutations
		const newVariantValues = abTestModal.variants[0].values.map((value) => {
			const deepCopiedValue = JSON.parse(JSON.stringify(value));
			return {
				...deepCopiedValue,
				value: '',
			};
		});

		const newVariant: ABTestVariant = {
			uuid: '',
			name: `Variant ${abTestModal.variants.length}`,
			values: newVariantValues,
			isActive: true,
		};

		// Check if the variant name already exists
		const variantExists = abTestModal.variants.some((v) => v.name.trim().toLowerCase() === newVariant.name.trim().toLowerCase());

		if (variantExists) {
			toast({
				title: `Variant name already exists: ${newVariant.name}`,
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top',
			});
		} else {
			// Update the modal with the new variant list
			updateAbTestModal({ variants: [...abTestModal.variants, newVariant] });
		}
	};

	const deleteABTestModalVariant = (index: number) => {
		const newVariants = abTestModal.variants.filter((_, i) => i !== index);
		updateAbTestModal({ variants: newVariants });
	};

	const updateAbTestModalVariantRCValue = (variantIndex: number, rcIndex: number, test: Partial<RemoteConfigValue>) => {
		const newVariants = abTestModal.variants.map((variant, vIndex) => ({
			...variant,
			values: variant.values.map((rcValue, rIndex) =>
				vIndex === variantIndex && rIndex === rcIndex ? { ...rcValue, ...test } : { ...rcValue },
			),
		}));

		updateAbTestModal({ variants: newVariants });
	};

	const updateAbTestModalVariant = (index: number, test: Partial<ABTestVariant>) => {
		const variants = abTestModal.variants;
		const variant = variants[index];
		variants[index] = { ...variant, ...test };
		updateAbTestModal({ variants });
	};

	// Pick segments that ids are in the abTestModal.filterOperations array
	const getSegmentOptions = () => {
		const segmentationOptions = segmentationData
			.filter((segment) => {
				return abTestModal.filterOperations[0].Values.some((segmentId) => {
					return segment.uid === segmentId;
				});
			})
			.map((segment) => {
				return { value: segment.uid, label: segment.name };
			});

		return segmentationOptions;
	};

	type multiSelection = {
		value: string;
		label: string;
	};

	function getSegmentNameFromUid(uid: string): string {
		let segment = segmentationData.find((segment) => segment.uid === uid);
		if (segment) {
			return segment.name;
		}

		return '';
	}

	const getSelectedSegments = (variantIndex: number, rcIndex: number, filterIndex: number): multiSelection[] => {
		// Find the exact filter at the given index
		const segmentFilter = abTestModal.variants[variantIndex]?.values[rcIndex]?.filterOperations[filterIndex];
		// Ensure it's a segmentation filter
		if (!segmentFilter || segmentFilter.Filter !== FilterType.Segmentation) return [];

		return segmentFilter.Values.map((value) => ({
			value: value.toString(),
			label: getSegmentNameFromUid(value.toString()),
		}));
	};

	const addSegmentationGroupToVariantValues = (variableIndex: number) => {
		const newVariants = abTestModal.variants.map((variant) => {
			let valuesCopy = [...variant.values];

			if (variableIndex < valuesCopy.length) {
				const baseValue = valuesCopy[variableIndex];
				const baseName = baseValue.key;

				// Find the last existing segmentation index for this base value
				let lastSegmentIndex =
					valuesCopy
						.map((v, i) => ({ name: v.key, index: i }))
						.filter((v) => v.name.startsWith(baseName))
						.map((v) => v.index)
						.pop() ?? variableIndex; // Default to original value index if no segments exist

				// Count existing segmentations to generate the correct suffix
				const newValue = {
					...baseValue,
					uid: '',
					id: 0,
					lastUpdated: 0,
					hash: '',
					key: `${baseName}`,
					value: '',
					filterOperations: [
						{
							Filter: FilterType.Segmentation,
							Condition:
								/*abTestModal.filterOperations.find((filter) => filter.Filter === FilterType.Segmentation)?.Condition ||*/
								FilterCondition.Include,
							Values: [],
						},
					],
				};

				// Insert after the last segment of the same name
				valuesCopy.splice(lastSegmentIndex + 1, 0, newValue);
			}

			return { ...variant, values: valuesCopy };
		});

		updateAbTestModal({ variants: newVariants });
	};

	const updateAbTestModalVariantRCValueFilterOperations = (variantIndex: number, variableIndex: number, test: Partial<FilterOperation>) => {
		// Deep copy variants
		const newVariants = abTestModal.variants.map((variant, vIndex) => {
			//if (vIndex !== variantIndex) return variant; // Keep other variants unchanged

			// Update only segmentation values (i.e., values with filterOperations)
			let updatedValues = variant.values.map((value, vIndex2) => {
				if (vIndex2 !== variableIndex) {
					console.log('Returning');
					return value; // Skip if not a segmentation group
				}

				// If filter operations is null, create a new one with test values

				let newFilterOperations =
					value.filterOperations && value.filterOperations.length > 0
						? [...value.filterOperations] // Keep existing filters
						: [
								{
									Filter: FilterType.Segmentation,
									Condition: FilterCondition.Include,
									Values: [...(test.Values || [])],
								},
							];

				return {
					...value,
					filterOperations: newFilterOperations.map((filter) =>
						filter.Filter === FilterType.Segmentation ? { ...filter, Values: [...(test.Values || [])] } : filter,
					),
				};
			});
			console.log('Updated values:', updatedValues);

			return { ...variant, values: updatedValues };
		});

		updateAbTestModal({ variants: newVariants });
	};

	const deleteSegmentationGroupFromVariantVariable = (variantIndex: number, variableIndex: number) => {
		const newVariants = abTestModal.variants.map((variant, vIndex) => {
			//if (vIndex !== variantIndex) return variant; // Keep other variants unchanged

			// Remove segmentation group value (values that have filterOperations)
			let updatedValues = variant.values.filter((_, vIndex2) => vIndex2 !== variableIndex);

			return { ...variant, values: updatedValues };
		});

		updateAbTestModal({ variants: newVariants });
	};

	const isMoveUpDisabled = (variantIndex: number, variableIndex: number) => {
		const variant = abTestModal.variants[variantIndex];
		const values = variant.values;

		// Get all segmentation groups for this variable
		const baseKey = values[variableIndex].key;
		const segmentIndices = values
			.map((v, index) => ({ key: v.key, index }))
			.filter(({ key }) => key.startsWith(baseKey))
			.map(({ index }) => index);

		// First segment group can't move up
		return segmentIndices.length === 0 || segmentIndices[0] === variableIndex || variableIndex === segmentIndices[0];
	};

	const isMoveDownDisabled = (variantIndex: number, variableIndex: number) => {
		const variant = abTestModal.variants[variantIndex];
		const values = variant.values;

		// Get all segmentation groups for this variable
		const baseKey = values[variableIndex].key;
		const segmentIndices = values
			.map((v, index) => ({ key: v.key, index }))
			.filter(({ key }) => key.startsWith(baseKey))
			.map(({ index }) => index);

		// Last segment group can't move down
		return segmentIndices.length === 0 || segmentIndices[segmentIndices.length - 1] === variableIndex;
	};

	const moveSegmentationGroup = (variantIndex: number, variableIndex: number, direction: number) => {
		const newVariants = abTestModal.variants.map((variant, vIndex) => {
			//if (vIndex !== variantIndex) return variant;

			let valuesCopy = [...variant.values];

			// Find all segmentations for this variable
			const baseKey = valuesCopy[variableIndex].key;
			const segmentIndices = valuesCopy
				.map((v, index) => ({ key: v.key, index }))
				.filter(({ key }) => key.startsWith(baseKey))
				.map(({ index }) => index);

			const currentIndex = segmentIndices.indexOf(variableIndex);
			const newIndex = variableIndex + direction;

			// If movement is within bounds, swap items
			if (currentIndex !== -1 && newIndex >= 0 && newIndex < valuesCopy.length) {
				[valuesCopy[variableIndex], valuesCopy[newIndex]] = [valuesCopy[newIndex], valuesCopy[variableIndex]];
			}

			return { ...variant, values: valuesCopy };
		});

		updateAbTestModal({ variants: newVariants });
	};

	const validateJson = (value: string): boolean => {
		value = value.trim();
		if (value.startsWith('{')) {
			try {
				JSON.parse(value);
				return true;
			} catch (e) {
				console.error('Invalid JSON');
				return false;
			}
		} else {
			return false;
		}
	};

	return (
		<FieldArray
			name="variants"
			render={(arrayHelpers) => (
				<FormControl mt={4} border={'0px'}>
					{abTestModal.variants.map((variant, variantIndex) => (
						<Stack
							key={variantIndex}
							paddingBottom={'50px'}
							opacity={variant.isActive ? 1 : 0.5}
							pointerEvents={variant.isActive ? 'auto' : 'none'}
						>
							<HStack>
								<Field name={`variants.${variantIndex}.name`}>
									{({ field, form }: { field: any; form: any }) => (
										<FormControl isInvalid={form.errors.variants?.[variantIndex]?.name && form.touched.variants?.[variantIndex]?.name}>
											<Input
												{...field}
												border={'0px'}
												fontStyle={'bold'}
												value={variant.name}
												isDisabled={!variant.isActive}
												onChange={(e) => {
													updateAbTestModalVariant(variantIndex, { name: e.target.value });
													//field.onChange(e);
													if (abTestModal) {
														form.setFieldValue(`variants.${variantIndex}.name`, e.target.value);
													}
												}}
											/>
											<FormErrorMessage>{form.errors.variants?.[variantIndex]?.name}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Button
									rightIcon={<FaTrashAlt />}
									backgroundColor="transparent"
									_hover={{ bg: 'transparent' }}
									isDisabled={!variant.isActive || abTestModal.variants.filter((v) => v.isActive).length <= 2}
									onClick={() => {
										if (isABTestNewlyCreated) {
											deleteABTestModalVariant(variantIndex);
										} else {
											updateAbTestModalVariant(variantIndex, { isActive: false });
										}
									}}
								/>
							</HStack>
							{variantIndex === 0 && (
								<Field name={`variants.${variantIndex}.name`}>
									{({ field, form }: { field: any; form: any }) => (
										<Select
											value=""
											onChange={(e) => {
												addVariableToVariants(e.target.selectedOptions[0].dataset.value || '');

												abTestModal.variants.forEach((variant, vIndex) => {
													variant.values.forEach((value, vIndex2) => {
														//if (vIndex === variantIndex && vIndex2 === 0) return;

														if (value.key === JSON.parse(e.target.selectedOptions[0].dataset.value!).key) {
															form.setFieldValue(
																`variants.${vIndex}.values.${vIndex2}.type`,
																JSON.parse(e.target.selectedOptions[0].dataset.value!).type,
															);
															form.setFieldValue(
																`variants.${vIndex}.values.${vIndex2}.key`,
																JSON.parse(e.target.selectedOptions[0].dataset.value!).key,
															);
														}
													});
												});
											}}
										>
											<option value="">Select a variable</option>
											{rcValues.map((remoteConfigValue) => (
												<option key={remoteConfigValue.uid} value={remoteConfigValue.key} data-value={JSON.stringify(remoteConfigValue)}>
													{remoteConfigValue.key}
												</option>
											))}
										</Select>
									)}
								</Field>
							)}

							<VStack direction="row" mt={2} paddingTop="15px">
								{variant.values.map((variable, variableIndex) => (
									<Field key={variable.key + variableIndex} name={`variants.${variantIndex}.values.${variableIndex}.key`}>
										{({ field, form }: { field: any; form: any }) => {
											const isError =
												form.errors.variants?.[variantIndex]?.values?.[variableIndex]?.value &&
												form.touched.variants?.[variantIndex]?.values?.[variableIndex]?.value;

											const renderInputField = () => {
												switch (variable.type) {
													case RCType.String:
														return (
															<Textarea
																{...field}
																size="sm"
																width="600px"
																height="20px"
																value={variable.value}
																isDisabled={!variant.isActive}
																onChange={(e) => {
																	let value = e.target.value;

																	if (value.startsWith('{')) {
																		const isValidJson = validateJson(value);
																		if (!isValidJson) {
																			toast({
																				title: 'Entered value is not a valid JSON',
																				description: 'Please enter a valid JSON value',
																				status: 'error',
																				duration: 5000,
																				isClosable: true,
																				position: 'top',
																			});
																		}
																		try {
																			value = JSON.stringify(JSON.parse(value), null, 0);
																		} catch (e) {
																			console.error('Invalid JSON');
																		}
																	}
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.value`, value);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.key`, variable.key);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.type`, variable.type);
																	updateAbTestModalVariantRCValue(variantIndex, variableIndex, { value });
																}}
															/>
														);
													case RCType.Integer:
													case RCType.Float:
														return (
															<NumberInput
																value={variable.value}
																size="sm"
																width="600px"
																height="40px"
																isDisabled={!variant.isActive}
																onChange={(value) => {
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.value`, value);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.key`, variable.key);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.type`, variable.type);
																	updateAbTestModalVariantRCValue(variantIndex, variableIndex, { value });
																}}
															>
																<NumberInputField height="40px" {...field} />
															</NumberInput>
														);
													case RCType.Boolean:
														return (
															<Select
																value={variable.value ? 'true' : 'false'}
																size="sm"
																width="600px"
																height="40px"
																isDisabled={!variant.isActive}
																onChange={(e) => {
																	const value = e.target.value === 'true';
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.value`, value);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.key`, variable.key);
																	form.setFieldValue(`variants.${variantIndex}.values.${variableIndex}.type`, variable.type);
																	updateAbTestModalVariantRCValue(variantIndex, variableIndex, { value });
																}}
															>
																<option value="true">True</option>
																<option value="false">False</option>
															</Select>
														);
													default:
														return null;
												}
											};

											const isFirstOccurrence = variant.values.findIndex((v) => v.key === variable.key) === variableIndex;

											return (
												<FormControl isInvalid={isError}>
													{isFirstOccurrence && (
														<InputGroup>
															<InputLeftAddon minWidth="80%" textAlign="center" display="flex" justifyContent="center">
																{variable.key}
															</InputLeftAddon>

															<InputRightAddon>
																<Button
																	rightIcon={<FaTrashAlt />}
																	backgroundColor="transparent"
																	_hover={{ bg: 'transparent' }}
																	isDisabled={!variant.isActive}
																	onClick={() => deleteVariableFromVariants(variable.key)}
																/>
															</InputRightAddon>

															<Button ml={2} colorScheme="green" onClick={() => addSegmentationGroupToVariantValues(variableIndex)}>
																Add Segment
															</Button>
														</InputGroup>
													)}

													{/* Render segmentation groups separately */}

													<HStack spacing={2} justifyContent="flex-start" alignItems="flex-start" width="100%" mt={2}>
														<Box width="270px" ml="30px">
															<ChakraReactSelect
																options={getSegmentOptions()}
																value={getSelectedSegments(variantIndex, variableIndex, 0)}
																isMulti
																menuPortalTarget={document.body}
																styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
																onChange={(e: any) => {
																	if (e) {
																		const selectedValues = e.map((segment: { value: string }) => segment.value);

																		updateAbTestModalVariantRCValueFilterOperations(variantIndex, variableIndex, {
																			Values: [...selectedValues],
																		});

																		// Update the form values for all variants with the same segmentation group
																		abTestModal.variants.forEach((variant, vIndex) => {
																			variant.values.forEach((value, vIndex2) => {
																				if (vIndex === variantIndex && vIndex2 === variableIndex) return;

																				if (value.key === variable.key) {
																					form.setFieldValue(
																						`variants.${vIndex}.values.${vIndex2}.filterOperations.0.Values`,
																						selectedValues,
																					);
																					form.setFieldValue(
																						`variants.${vIndex}.values.${vIndex2}.filterOperations.0.Filter`,
																						FilterType.Segmentation,
																					);
																					form.setFieldValue(
																						`variants.${vIndex}.values.${vIndex2}.filterOperations.0.Condition`,
																						abTestModal.filterOperations[0].Condition,
																					);
																				}
																			});
																		});
																	}
																}}
															/>
														</Box>

														{renderInputField()}

														<Button
															colorScheme="red"
															size="sm"
															onClick={() => {
																deleteSegmentationGroupFromVariantVariable(variantIndex, variableIndex);
															}}
														>
															<FaTrashAlt />
														</Button>

														{/* Up Button */}
														<Button
															colorScheme="blue"
															size="sm"
															onClick={() => moveSegmentationGroup(variantIndex, variableIndex, -1)}
															isDisabled={isMoveUpDisabled(variantIndex, variableIndex)}
														>
															<FaArrowUp />
														</Button>

														{/* Down Button */}
														<Button
															colorScheme="blue"
															size="sm"
															onClick={() => moveSegmentationGroup(variantIndex, variableIndex, 1)}
															isDisabled={isMoveDownDisabled(variantIndex, variableIndex)}
														>
															<FaArrowDown />
														</Button>
													</HStack>

													<FormErrorMessage>{form.errors.variants?.[variantIndex]?.values?.[variableIndex]?.key}</FormErrorMessage>
												</FormControl>
											);
										}}
									</Field>
								))}
							</VStack>

							{abTestModal?.variants && variantIndex === abTestModal.variants.length - 1 && (
								<Field name="addVariant">
									{({ field, form }: { field: any; form: any }) => (
										<Button
											isDisabled={!isABTestNewlyCreated}
											rightIcon={<LuTestTube2 />}
											colorScheme="blue"
											variant="outline"
											marginTop={'50px'}
											onClick={() => {
												addToVariants();
												form.setFieldValue(`variants.${variantIndex + 1}.name`, variant.name);
											}}
										>
											Add Variant
										</Button>
									)}
								</Field>
							)}
						</Stack>
					))}
				</FormControl>
			)}
		/>
	);
}

export default SegmentedVariants;
