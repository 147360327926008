import semver from 'semver';

import { Countries } from '../enums';
import { FilterCondition, Platform } from '@repo/alictus-common/enums/filter_condition';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { RemoteConfigValue } from '@repo/alictus-common/types/remote_config';
import { FilterOperation, SegmentationFilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

function checkVersionOverlap(ops1: FilterOperation[], ops2: FilterOperation[]): boolean {
	const combinedRange1 = combineOperations(ops1);
	const combinedRange2 = combineOperations(ops2);

	if (!combinedRange1 || !combinedRange2) {
		return false;
	}

	// Extract all possible versions dynamically from both operations
	const possibleVersions = extractPossibleVersions(ops1, ops2);

	// Handle NotEqual/Exclude case separately
	if (combinedRange1.excludes.length || combinedRange2.excludes.length) {
		return checkExclusionOverlap(combinedRange1, combinedRange2, possibleVersions);
	}

	return semver.intersects(combinedRange1.range, combinedRange2.range);
}

function checkBuildNumberOverlap(ops1: FilterOperation[], ops2: FilterOperation[]): boolean {
	//Convert the build number to semver format
	const convertBuildNumberToSemver = (buildNumber: string) => {
		return `${buildNumber}.0.0`;
	};

	const convertedOps1 = ops1.map((op) => {
		return {
			...op,
			Values: op.Values.map((v) => convertBuildNumberToSemver(v.toString())),
		};
	});

	const convertedOps2 = ops2.map((op) => {
		return {
			...op,
			Values: op.Values.map((v) => convertBuildNumberToSemver(v.toString())),
		};
	});

	const combinedRange1 = combineOperations(convertedOps1);
	const combinedRange2 = combineOperations(convertedOps2);

	if (!combinedRange1 || !combinedRange2) {
		return false;
	}

	// Extract all possible versions dynamically from both operations
	const possibleVersions = extractPossibleVersions(convertedOps1, convertedOps2);

	// Handle NotEqual/Exclude case separately
	if (combinedRange1.excludes.length || combinedRange2.excludes.length) {
		return checkExclusionOverlap(combinedRange1, combinedRange2, possibleVersions);
	}

	return semver.intersects(combinedRange1.range, combinedRange2.range);
}

function combineOperations(ops: FilterOperation[]): { range: string; excludes: string[] } {
	const ranges: string[] = [];
	const equals: string[] = [];
	const excludes: string[] = [];

	ops.forEach((filterOp) => {
		filterOp.Values.forEach((value) => {
			const version = value.toString();
			switch (filterOp.Condition) {
				case FilterCondition.GreaterThan:
					ranges.push(`>${version}`);
					break;
				case FilterCondition.LessThan:
					ranges.push(`<${version}`);
					break;
				case FilterCondition.GreaterThanOrEqual:
					ranges.push(`>=${version}`);
					break;
				case FilterCondition.LessThanOrEqual:
					ranges.push(`<=${version}`);
					break;
				case FilterCondition.Equal:
				case FilterCondition.Include:
					equals.push(`=${version}`);
					break;
				case FilterCondition.NotEqual:
				case FilterCondition.Exclude:
					excludes.push(version); // Store separately
					break;
			}
		});
	});

	let finalRange = '';

	// Handle range conditions (AND - space-separated)
	if (ranges.length > 0) {
		finalRange += ranges.join(' ');
	}

	// Handle equality conditions (OR - || separated)
	if (equals.length > 0) {
		if (finalRange) {
			// If range exists, wrap equality conditions in OR brackets
			finalRange = `(${finalRange}) || (${equals.join(' || ')})`;
		} else {
			finalRange = equals.join(' || ');
		}
	}

	return {
		range: finalRange || '*', // If empty, allow everything
		excludes,
	};
}

function checkExclusionOverlap(
	range1: { range: string; excludes: string[] },
	range2: { range: string; excludes: string[] },
	possibleVersions: string[],
): boolean {
	// Get versions that match each range
	const includedVersions1 = getMatchingVersions(range1.range, possibleVersions);
	const includedVersions2 = getMatchingVersions(range2.range, possibleVersions);

	// Filter out excluded versions
	const validVersions1 = includedVersions1.filter((v) => !range1.excludes.includes(v));
	const validVersions2 = includedVersions2.filter((v) => !range2.excludes.includes(v));

	// If there is at least one common valid version, return true (overlap exists)
	return validVersions1.some((v) => validVersions2.includes(v));
}

function extractPossibleVersions(ops1: FilterOperation[], ops2: FilterOperation[]): string[] {
	// Collect all unique version values from ops1 and ops2
	const versions = new Set<string>();

	[...ops1, ...ops2].forEach((filterOp) => {
		filterOp.Values.forEach((value) => {
			versions.add(value.toString());
		});
	});

	return Array.from(versions).sort(semver.compare); // Sort versions in semver order
}

function getMatchingVersions(range: string, possibleVersions: string[]): string[] {
	if (range === '*') return possibleVersions;

	return possibleVersions.filter((version) => semver.satisfies(version, range));
}

// Helper function to determine data type of selector
function getSelectorDataType(filterOperation: SegmentationFilterOperation): 'number' | 'string' | 'boolean' {
	if (filterOperation.Values.length === 0) return 'string';
	const value = filterOperation.Values[0].toString();

	// If value is convertible to number, return 'number'
	if (!isNaN(parseFloat(value))) return 'number';

	// If value is convertible to boolean, return 'boolean'
	if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') return 'boolean';

	return 'string';
}

// Numeric range check helpers
interface NumericRange {
	min: number;
	max: number;
	excludeMin: boolean;
	excludeMax: boolean;
}

function getNumericRange(op: SegmentationFilterOperation): NumericRange[] | null {
	if (op.Values.length === 0) return null;
	const numericValue = parseFloat(op.Values[0].toString());
	if (isNaN(numericValue)) return null;

	let min = -Infinity;
	let max = Infinity;
	let excludeMin = false;
	let excludeMax = false;

	switch (op.Condition) {
		case FilterCondition.GreaterThan:
			min = numericValue;
			excludeMin = true;
			break;
		case FilterCondition.GreaterThanOrEqual:
			min = numericValue;
			break;
		case FilterCondition.LessThan:
			max = numericValue;
			excludeMax = true;
			break;
		case FilterCondition.LessThanOrEqual:
			max = numericValue;
			break;
		case FilterCondition.Equal:
		case FilterCondition.Include:
			min = max = numericValue;
			break;
		case FilterCondition.NotEqual:
		case FilterCondition.Exclude:
			// Create two separate ranges: (-Infinity to value) and (value to Infinity)
			return [
				{ min: -Infinity, max: numericValue, excludeMin: false, excludeMax: true }, // Less than value
				{ min: numericValue, max: Infinity, excludeMin: true, excludeMax: false }, // Greater than value
			];
		default:
			return null;
	}

	return [{ min, max, excludeMin, excludeMax }];
}

function computeEffectiveRange(ops: SegmentationFilterOperation[]): NumericRange {
	let min = -Infinity;
	let max = Infinity;
	let excludeMin = false;
	let excludeMax = false;

	for (const op of ops) {
		const ranges = getNumericRange(op);
		if (!ranges) continue;

		for (const range of ranges) {
			if (range.min > min) {
				min = range.min;
				excludeMin = range.excludeMin;
			} else if (range.min === min) {
				excludeMin = excludeMin || range.excludeMin;
			}

			if (range.max < max) {
				max = range.max;
				excludeMax = range.excludeMax;
			} else if (range.max === max) {
				excludeMax = excludeMax || range.excludeMax;
			}
		}
	}

	return { min, max, excludeMin, excludeMax };
}

function checkNumericOverlap(ops1: SegmentationFilterOperation[], ops2: SegmentationFilterOperation[]): boolean {
	const range1 = computeEffectiveRange(ops1);
	const range2 = computeEffectiveRange(ops2);

	// Check if the two ranges are completely disjoint
	const lowerThanUpper = range1.max < range2.min || (range1.max === range2.min && (range1.excludeMax || range2.excludeMin));
	const higherThanLower = range1.min > range2.max || (range1.min === range2.max && (range1.excludeMin || range2.excludeMax));

	return !(lowerThanUpper || higherThanLower);
}

// String overlap check
function checkStringOverlap(op1: SegmentationFilterOperation, op2: SegmentationFilterOperation): boolean {
	const values1 = new Set(op1.Values.map((v) => v.toString()));
	const values2 = new Set(op2.Values.map((v) => v.toString()));

	if (op1.Condition === FilterCondition.Equal && op2.Condition === FilterCondition.Equal) {
		return [...values1].some((v) => values2.has(v));
	}

	if (op1.Condition === FilterCondition.NotEqual && op2.Condition === FilterCondition.NotEqual) {
		// Assume overlap unless both exclude all possible values (unknown here)
		return true;
	}

	if (op1.Condition === FilterCondition.Equal && op2.Condition === FilterCondition.NotEqual) {
		return [...values1].some((v) => !values2.has(v));
	}

	if (op1.Condition === FilterCondition.NotEqual && op2.Condition === FilterCondition.Equal) {
		return [...values2].some((v) => !values1.has(v));
	}

	return false;
}

// Boolean overlap check
function checkBooleanOverlap(op1: SegmentationFilterOperation, op2: SegmentationFilterOperation): boolean {
	const getBooleanValue = (op: SegmentationFilterOperation): boolean | null => {
		if (op.Values.length === 0) return null;
		const value = op.Values[0].toString().toLowerCase();
		return value === 'true' ? true : value === 'false' ? false : null;
	};

	const value1 = getBooleanValue(op1);
	const value2 = getBooleanValue(op2);
	if (value1 === null || value2 === null) return false;

	const effective1 = [FilterCondition.Exclude, FilterCondition.NotEqual].includes(op1.Condition) ? !value1 : value1;
	const effective2 = [FilterCondition.Exclude, FilterCondition.NotEqual].includes(op2.Condition) ? !value2 : value2;

	return effective1 === effective2;
}

// Modified checkSegmentationOverlap function
function checkSegmentationOverlap(
	op1: SegmentationFilterOperation | undefined,
	op2: SegmentationFilterOperation | undefined,
	existingSegments: PlayerSegment[],
): boolean | undefined {
	if (!op1 || !op2) return true;
	if (op1.SelectorUid !== op2.SelectorUid) return false;

	const segmentationList1 = existingSegments.filter((s) => op1.Values.includes(s.uid));
	const segmentationList2 = existingSegments.filter((s) => op2.Values.includes(s.uid));

	let overlapDetected = false;

	for (const seg1 of segmentationList1) {
		for (const seg2 of segmentationList2) {
			const numericOps1: SegmentationFilterOperation[] = [];
			const numericOps2: SegmentationFilterOperation[] = [];

			for (const filterOp1 of seg1.filterOperations) {
				for (const filterOp2 of seg2.filterOperations) {
					if (filterOp1.SelectorUid === filterOp2.SelectorUid) {
						const dataType = getSelectorDataType(filterOp1);

						if (dataType === 'number') {
							numericOps1.push(filterOp1);
							numericOps2.push(filterOp2);
						}
					}
				}
			}

			// If there are numeric operations, check for overlap
			if (numericOps1.length > 0 && numericOps2.length > 0) {
				if (checkNumericOverlap(numericOps1, numericOps2)) {
					return true;
				}
			}

			for (const filterOp1 of seg1.filterOperations) {
				for (const filterOp2 of seg2.filterOperations) {
					if (filterOp1.SelectorUid === filterOp2.SelectorUid) {
						const dataType = getSelectorDataType(filterOp1);

						let hasOverlap = false;
						switch (dataType) {
							case 'string':
								hasOverlap = checkStringOverlap(filterOp1, filterOp2);
								break;
							case 'boolean':
								hasOverlap = checkBooleanOverlap(filterOp1, filterOp2);
								break;
							default:
								hasOverlap = false;
						}

						if (hasOverlap) {
							overlapDetected = true;
							break;
						}
					}
				}
			}
		}
	}

	return overlapDetected;
}

function checkCountryOverlap(op1: FilterOperation | undefined, op2: FilterOperation | undefined): boolean {
	// If either of the filter operations is undefined, there will be overlap
	if (!op1 || !op2) return true;

	// If there is "All" selected in any of the filter operations, there will be overlap
	if (op1.Values.includes('All') || op2.Values.includes('All')) return true;

	// If filter condition is Include, then create set with given countries,
	// otherwise create set with all countries that are not include given countries
	const countrySet1 = new Set(
		op1.Condition === FilterCondition.Include
			? op1.Values.map((v) => v.toString())
			: Object.values(Countries.filter((c) => !op1.Values.includes(c.code)).map((c) => c.code)),
	);
	const countrySet2 = new Set(
		op2.Condition === FilterCondition.Include
			? op2.Values.map((v) => v.toString())
			: Object.values(Countries.filter((c) => !op2.Values.includes(c.code)).map((c) => c.code)),
	);

	for (const val of countrySet1) {
		if (countrySet2.has(val)) return true;
	}
	return false;
}

export function detectConfigOverlap(
	newConfig: RemoteConfigValue,
	existingConfigs: RemoteConfigValue[],
	existingSegments: PlayerSegment[],
): RemoteConfigValue | null | undefined {
	for (const existingConfig of existingConfigs) {
		if (existingConfig.platform !== newConfig.platform && existingConfig.platform !== Platform.All && newConfig.platform !== Platform.All) {
			continue;
		}

		const groupFiltersByType = (filters: FilterOperation[]) => {
			return filters.reduce((acc, filter) => {
				if (!acc.has(filter.Filter)) {
					acc.set(filter.Filter, []);
				}
				acc.get(filter.Filter)!.push(filter);
				return acc;
			}, new Map<FilterType, FilterOperation[]>());
		};

		const newFilters = groupFiltersByType(newConfig.filterOperations);
		const existingFilters = groupFiltersByType(existingConfig.filterOperations);
		let overlapDetected = false;

		const versionOverlap =
			newFilters.has(FilterType.Version) && existingFilters.has(FilterType.Version)
				? checkVersionOverlap(newFilters.get(FilterType.Version)!, existingFilters.get(FilterType.Version)!)
				: true;

		const buildOverlap =
			newFilters.has(FilterType.BuildNumber) && existingFilters.has(FilterType.BuildNumber)
				? checkBuildNumberOverlap(newFilters.get(FilterType.BuildNumber)!, existingFilters.get(FilterType.BuildNumber)!)
				: true;

		const countryOverlap =
			newFilters.has(FilterType.Country) && existingFilters.has(FilterType.Country)
				? checkCountryOverlap(newFilters.get(FilterType.Country)![0], existingFilters.get(FilterType.Country)![0])
				: true;

		const segmentOverlap =
			newFilters.has(FilterType.Segmentation) && existingFilters.has(FilterType.Segmentation)
				? checkSegmentationOverlap(
						newFilters.get(FilterType.Segmentation)![0] as SegmentationFilterOperation,
						existingFilters.get(FilterType.Segmentation)![0] as SegmentationFilterOperation,
						existingSegments,
					)
				: true;

		if (segmentOverlap === undefined) {
			return undefined;
		}
		if (versionOverlap && buildOverlap && countryOverlap && segmentOverlap) {
			overlapDetected = true;
		}

		if (overlapDetected) return existingConfig;
	}
	return null;
}
