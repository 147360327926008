import React, { useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	FormHelperText,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Stack,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import { populateFilters } from '../utility';
import config from '../config';
import globalStore from '../store';
import { RCType, RemoteConfigValue } from '@repo/alictus-common/types';
import { FilterCondition } from '@repo/alictus-common/enums/filter_condition';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import { Countries } from './enums';
import { Platform } from '@repo/alictus-common/enums/platform';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { FilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';
import remoteConfigSchema from '@repo/alictus-common/validation/remote_config_schema';
import { detectConfigOverlap } from './utils/overlap';
//import { detectConfigOverlap } from '@repo/alictus-common/utility/rc_overlap_detection';

// import { toFormikValidationSchema } from 'zod-formik-adapter';

// The initial state for a new filter
const countryList = Countries.map((country) => ({ value: country.code, label: country.name }));
const JSON_SIZE_LIMIT_KB = 2000;
const KB_DIVISOR = 1024;

interface jsonSizeInfoProps {
	currentSize: string;
	minifiedSize: string;
	isExceeding: boolean;
}

export function RemoteConfigVariableModal({
	disclosure,
	remoteData,
	environment,
}: {
	disclosure: { isOpen: boolean; onClose: () => void };
	remoteData: RemoteConfigValue | null;
	environment: string;
}) {
	const globalData = globalStore();
	const toast = useToast();
	const [segments, setSegments] = React.useState<PlayerSegment[]>([]);
	const [segmentNames, setSegmentNames] = React.useState<any[]>([]);
	const [remoteConfigValue, setRemoteConfigValue] = React.useState<RemoteConfigValue>();
	const hasFetchedData = useRef(false);
	const [title, setTitle] = React.useState<string>('Create Remote Config Variable');
	const [updatingExistingVariable, setUpdatingExistingVariable] = React.useState<boolean>(false);
	const [isJsonValid, setIsJsonValid] = React.useState<boolean>(true);
	const [isJsonValueEntered, setIsJsonValueEntered] = React.useState<boolean>(false);
	const [jsonError, setJsonError] = React.useState<string>('');
	const [isEnvironmentFilterDisabled, setIsEnvironmentFilterDisabled] = React.useState<boolean>(true);
	const isMockCreator = globalData.user?.roles.includes('MOCK_CREATOR') || false;
	const isAdminOrProduct = globalData.user?.roles.includes('ADMIN') || globalData.user?.roles.includes('PRODUCT') || false;
	const [isMock, setIsMock] = React.useState(false);
	const [jsonSizeInfo, setJsonSizeInfo] = React.useState<jsonSizeInfoProps>({ currentSize: '', minifiedSize: '', isExceeding: false });

	const validateJson = (value: string): boolean => {
		value = value.trim();
		if (value.startsWith('{')) {
			setIsJsonValueEntered(true);
			try {
				JSON.parse(value);
				setIsJsonValid(true);
				setJsonError('');
				return true;
			} catch (e) {
				setIsJsonValid(false);
				setJsonError((e as Error).message);
				return false;
			}
		} else {
			setIsJsonValueEntered(false);
			setIsJsonValid(true);
			setJsonError('');
			return false;
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (disclosure.isOpen && !hasFetchedData.current) {
				hasFetchedData.current = true;
				if (remoteData) {
					if (remoteData.type === RCType.String) {
						const result = await fetchFullRemoteConfig(remoteData.id);
						if (result) {
							remoteData = result;
						}
					}
					if (remoteData.type === RCType.String && remoteData.value.startsWith('{')) {
						remoteData.value = JSON.stringify(JSON.parse(remoteData.value), null, 2);
					}

					let clonedRemoteData = _.cloneDeep(remoteData);
					setTitle('Edit Remote Config Variable');
					setUpdatingExistingVariable(true);
					setIsEnvironmentFilterDisabled(false);
					clonedRemoteData.filterOperations.push(
						{
							Filter: FilterType.Version,
							Condition: FilterCondition.NoOp,
							Values: [],
						},
						{
							Filter: FilterType.BuildNumber,
							Condition: FilterCondition.NoOp,
							Values: [],
						},
					);
					setRemoteConfigValue(clonedRemoteData);
					setIsMock(clonedRemoteData.isMock);
				} else {
					setRemoteConfigValue({
						abTestUid: null,
						isActive: false,
						isDeleted: false,
						isOverWritten: false,
						isPersonal: true,
						userId: 0,
						variantId: null,
						id: 0,
						uid: '',
						key: '',
						description: '',
						type: RCType.String,
						value: '',
						isTest: true,
						platform: Platform.All,
						filterOperations: [
							{
								Filter: FilterType.Version,
								Condition: FilterCondition.NoOp,
								Values: [],
							},
							{
								Filter: FilterType.BuildNumber,
								Condition: FilterCondition.NoOp,
								Values: [],
							},
						],
						lastUpdated: 0,
						lastUpdater: '',
						isMock: false,
						hash: null,
					});
					setIsEnvironmentFilterDisabled(true);
					setIsMock(isMockCreator);
				}

				let bearerToken = 'Bearer ' + globalData.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
				axios.get(url, { headers: { Authorization: bearerToken } }).then((response) => {
					setSegments(response.data as PlayerSegment[]);
					setSegmentNames(extractSegmentNames(response.data as PlayerSegment[]));
				});
			}
		};

		fetchData();

		return () => {
			if (!disclosure.isOpen) {
				setTitle('Create Remote Config Variable');
				setUpdatingExistingVariable(false);
				setRemoteConfigValue(undefined);
				setSegments([]);
				setSegmentNames([]);
				setIsJsonValid(true);
				setIsJsonValueEntered(false);
				setJsonError('');
				hasFetchedData.current = false;
			}
		};
	}, [disclosure.isOpen, remoteData]);

	const extractSegmentNames = (segments: PlayerSegment[]): any[] => {
		return segments.map((segment) => ({ value: segment.uid, label: segment.name }));
	};

	function updateRemoteConfigValue(updatedValue: Partial<RemoteConfigValue>) {
		//Todo: This is a hack to set boolean value to true if it is empty
		if (updatedValue.type === RCType.Boolean && remoteConfigValue?.value === '') {
			updatedValue.value = true;
		}

		setRemoteConfigValue((prev) => (prev ? { ...prev, ...updatedValue } : undefined));
	}

	function onEnvironmentChange(envValue: string) {
		if (envValue === 'development') {
			updateRemoteConfigValue({ isTest: true });
		} else if (envValue === 'production') {
			updateRemoteConfigValue({ isTest: false });
		} else {
			throw new Error('Invalid environment value');
		}
	}

	function onPrivacyModeChange(envValue: string) {
		if (envValue === 'isPersonal') {
			updateRemoteConfigValue({ isPersonal: true });
		} else if (envValue === 'isPublic') {
			updateRemoteConfigValue({ isPersonal: false });
		} else {
			throw new Error('Invalid environment value');
		}
	}

	function updateCountryFilter(updatedValue: FilterOperation) {
		let countryFilter = remoteConfigValue?.filterOperations.find((filter) => filter.Filter === FilterType.Country);
		if (countryFilter && remoteConfigValue) {
			//remove existing country filter
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Country);
			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: updatedFilters } : undefined));
		}

		updatedValue.Filter = FilterType.Country;
		setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: [...prev.filterOperations, updatedValue] } : undefined));
	}

	function getCountryFilter(): FilterOperation {
		if (remoteConfigValue) {
			const result = remoteConfigValue.filterOperations.find((filter) => filter.Filter === FilterType.Country) as FilterOperation;
			if (result) {
				return result;
			}
		}
		return { Filter: FilterType.Country, Condition: FilterCondition.NoOp, Values: [] };
	}

	type multiSelection = {
		value: string;
		label: string;
	};

	function getSegmentFilter(): FilterOperation {
		if (remoteConfigValue) {
			const result = remoteConfigValue.filterOperations.find((filter) => filter.Filter === FilterType.Segmentation) as FilterOperation;
			if (result) {
				return result;
			}
		}
		return { Filter: FilterType.Segmentation, Condition: FilterCondition.NoOp, Values: [] };
	}

	function updateSegmentCondition(condition: FilterCondition) {
		let segmentFilter = getSegmentFilter();
		segmentFilter.Condition = condition;
		segmentFilter.Filter = FilterType.Segmentation;
		if (remoteConfigValue) {
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);
			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: [...updatedFilters, segmentFilter] } : undefined));
		}
	}

	function updateSegmentFilter(selection: multiSelection[]) {
		let segmentFilter = getSegmentFilter();
		segmentFilter.Values = selection.map((segment) => segment.value);
		segmentFilter.Filter = FilterType.Segmentation;
		if (remoteConfigValue) {
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Segmentation);

			setRemoteConfigValue((prev) =>
				prev
					? {
							...prev,
							filterOperations: [...updatedFilters, segmentFilter],
						}
					: undefined,
			);
		}
	}

	function getSelectedSegments(): multiSelection[] {
		//get country filter values and generate country selection from countryList
		let segmentFilter = getSegmentFilter();
		let selectedSegments: multiSelection[] = [];
		if (segmentFilter.Values) {
			selectedSegments = segmentFilter.Values.map((segment) => {
				return { value: segment as string, label: getSegmentNameFromUid(segment as string) };
			});
		}
		return selectedSegments;
	}

	function getSegmentNameFromUid(uid: string): string {
		let segment = segments.find((segment) => segment.uid === uid);
		if (segment) {
			return segment.name;
		}

		return '';
	}

	function getSelectedCountries(): multiSelection[] {
		//get country filter values and generate country selection from countryList
		let countryFilter = getCountryFilter();
		let selectedCountries: multiSelection[] = [];
		if (countryFilter.Values) {
			selectedCountries = countryFilter.Values.map((country) => {
				return { value: country as string, label: country as string };
			});
		}
		return selectedCountries;
	}

	function updateCountrySelection(selectedCountries: multiSelection[]) {
		let countryFilter = getCountryFilter();
		countryFilter.Values = selectedCountries.map((country) => country.value);
		updateCountryFilter(countryFilter);
	}

	function updateVersionFilterCondition(index: number, filterCondition: FilterCondition) {
		if (remoteConfigValue) {
			const versionFilters = remoteConfigValue.filterOperations
				.filter((filter) => filter.Filter === FilterType.Version)
				.map((filter, i) => {
					if (i === index) {
						return { ...filter, ...{ Condition: filterCondition } };
					}
					return filter;
				});

			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Version);
			updatedFilters.push(...versionFilters);

			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: updatedFilters } : undefined));
		}
	}

	function updateBuildNumberFilterCondition(index: number, filterCondition: FilterCondition) {
		if (remoteConfigValue) {
			const buildNumberFilters = remoteConfigValue.filterOperations
				.filter((filter) => filter.Filter === FilterType.BuildNumber)
				.map((filter, i) => {
					if (i === index) {
						return { ...filter, ...{ Condition: filterCondition } };
					}
					return filter;
				});

			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.BuildNumber);
			updatedFilters.push(...buildNumberFilters);

			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: updatedFilters } : undefined));
		}
	}

	function updateVersionFilterValue(index: number, value: string) {
		//remote empty spaces
		value = value.trim();

		if (remoteConfigValue) {
			const updatedVersionFilters = remoteConfigValue.filterOperations
				.filter((filter) => filter.Filter === FilterType.Version)
				.map((filter, i) => {
					if (i === index) {
						const value_list = value.split(',').map((val) => val.trim());
						return { ...filter, ...{ Values: value_list } };
					}
					return filter;
				});

			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Version);
			updatedFilters.push(...updatedVersionFilters);
			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: updatedFilters } : undefined));
		}
	}

	function updateBuildNumberFilterValue(index: number, value: string) {
		//remote empty spaces
		value = value.trim();

		if (remoteConfigValue) {
			const updatedVersionFilters = remoteConfigValue.filterOperations
				.filter((filter) => filter.Filter === FilterType.BuildNumber)
				.map((filter, i) => {
					if (i === index) {
						const value_list = value.split(',').map((val) => val.trim());
						return { ...filter, ...{ Values: value_list } };
					}
					return filter;
				});

			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.BuildNumber);
			updatedFilters.push(...updatedVersionFilters);
			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: updatedFilters } : undefined));
		}
	}

	function getVersionFilterValue(filterOperation: FilterOperation): string {
		if (filterOperation !== undefined && filterOperation.Values && filterOperation.Values.length > 0) {
			const val = filterOperation.Values.join(',');
			return val;
		}
		return '';
	}

	function getBuildNumberFilterValue(filterOperation: FilterOperation): string {
		if (filterOperation !== undefined && filterOperation.Values && filterOperation.Values.length > 0) {
			const val = filterOperation.Values.join(',');
			return val;
		}
		return '';
	}

	function addVersionFilter() {
		if (remoteConfigValue) {
			remoteConfigValue.filterOperations.push({
				Filter: FilterType.Version,
				Condition: FilterCondition.NoOp,
				Values: [],
			});
			setRemoteConfigValue((prev) => (prev ? { ...prev } : undefined));
		}
	}

	function addBuildNumberFilter() {
		if (remoteConfigValue) {
			remoteConfigValue.filterOperations.push({
				Filter: FilterType.BuildNumber,
				Condition: FilterCondition.NoOp,
				Values: [],
			});
			setRemoteConfigValue((prev) => (prev ? { ...prev } : undefined));
		}
	}

	function removeVersionFilter(index: number) {
		if (remoteConfigValue) {
			const updatedVersionFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter === FilterType.Version);
			const updatedFilters = updatedVersionFilters.filter((filter, i) => i !== index);

			const otherFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.Version);

			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: [...otherFilters, ...updatedFilters] } : undefined));
		}
	}

	function removeBuildNumberFilter(index: number) {
		if (remoteConfigValue) {
			const updatedBuildNumberFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter === FilterType.BuildNumber);
			const updatedFilters = updatedBuildNumberFilters.filter((filter, i) => i !== index);

			const otherFilters = remoteConfigValue.filterOperations.filter((filter) => filter.Filter !== FilterType.BuildNumber);

			setRemoteConfigValue((prev) => (prev ? { ...prev, filterOperations: [...otherFilters, ...updatedFilters] } : undefined));
		}
	}

	async function fetchFullRemoteConfig(remoteConfigId: number): Promise<RemoteConfigValue | null> {
		return await axios
			.get(`${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${remoteConfigId}`, {
				headers: { Authorization: `Bearer ${globalData.JWTToken}` },
			})
			.then((response) => {
				return response.data as RemoteConfigValue;
			})
			.catch((error) => {
				console.error('Error fetching full remote config:', error);
				if (error.response) {
					console.error('Response data:', error.response.data);
					console.error('Response status:', error.response.status);
				} else if (error.request) {
					console.error('No response received:', error.request);
				} else {
					console.error('Error setting up request:', error.message);
				}
				return null;
			});
	}

	function sendUpdateRequest(data: RemoteConfigValue | undefined) {
		if (data && data.type === RCType.String && data.value.startsWith('{')) {
			data.value = JSON.stringify(JSON.parse(data.value), null, 0);
		}

		return axios.put(`${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${remoteConfigValue?.id}`, data, {
			headers: { Authorization: `Bearer ${globalData.JWTToken}` },
		});
	}

	function sendCreateRequest(data: RemoteConfigValue | undefined) {
		if (data && data.type === RCType.String && data.value.startsWith('{')) {
			data.value = JSON.stringify(JSON.parse(data.value), null, 0);
		}

		data!.isMock = isMock;

		return axios.post(`${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value`, data, {
			headers: { Authorization: `Bearer ${globalData.JWTToken}` },
		});
	}

	function removeEmptySegmentFilters(remoteConfigValue: RemoteConfigValue | undefined) {
		if (remoteConfigValue) {
			//filter version filters that are empty and remove them
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => {
				if (filter.Filter === FilterType.Segmentation) {
					return filter.Values.length > 0 && filter.Condition !== FilterCondition.NoOp;
				}
				return true;
			});

			//setRemoteConfigValue((prev) => prev ? {...prev, filterOperations: updatedFilters} : undefined);
			return { ...remoteConfigValue, filterOperations: updatedFilters };
		}
		return undefined;
	}

	function removeEmptyCountryFilters(remoteConfigValue: RemoteConfigValue | undefined) {
		if (remoteConfigValue) {
			//filter version filters that are empty and remove them
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => {
				if (filter.Filter === FilterType.Country) {
					return filter.Values.length > 0 && filter.Condition !== FilterCondition.NoOp;
				}
				return true;
			});

			//setRemoteConfigValue((prev) => prev ? {...prev, filterOperations: updatedFilters} : undefined);
			return { ...remoteConfigValue, filterOperations: updatedFilters };
		}
		return undefined;
	}

	function removeEmptyVersionFilters(remoteConfigValue: RemoteConfigValue | undefined) {
		if (remoteConfigValue) {
			//filter version filters that are empty and remove them
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => {
				if (filter.Filter === FilterType.Version) {
					return filter.Values.length > 0 && filter.Condition !== FilterCondition.NoOp;
				}
				return true;
			});

			//setRemoteConfigValue((prev) => prev ? {...prev, filterOperations: updatedFilters} : undefined);
			return { ...remoteConfigValue, filterOperations: updatedFilters };
		}
		return undefined;
	}

	function removeEmptyBuildNumberFilters(remoteConfigValue: RemoteConfigValue | undefined) {
		if (remoteConfigValue) {
			//filter version filters that are empty and remove them
			let updatedFilters = remoteConfigValue.filterOperations.filter((filter) => {
				if (filter.Filter === FilterType.BuildNumber) {
					return filter.Values.length > 0 && filter.Condition !== FilterCondition.NoOp;
				}
				return true;
			});

			//setRemoteConfigValue((prev) => prev ? {...prev, filterOperations: updatedFilters} : undefined);
			return { ...remoteConfigValue, filterOperations: updatedFilters };
		}
		return undefined;
	}

	return (
		<Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
			<ModalOverlay />
			<ModalContent maxW="950px">
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={{
						key: remoteConfigValue?.key,
						description: remoteConfigValue?.description,
						type: remoteConfigValue?.type,
						value: remoteConfigValue?.value,
						isPersonal: remoteConfigValue?.isPersonal,
						isTest: remoteConfigValue?.isTest,
						platform: remoteConfigValue?.platform,
						filterOperations: populateFilters(remoteConfigValue?.filterOperations),
					}}
					//validationSchema={toFormikValidationSchema(remoteConfigSchema)}
					onSubmit={(values, { setSubmitting }) => {
						let updatedRemoteConfigValue = removeEmptySegmentFilters(remoteConfigValue);
						updatedRemoteConfigValue = removeEmptyCountryFilters(updatedRemoteConfigValue);
						updatedRemoteConfigValue = removeEmptyVersionFilters(updatedRemoteConfigValue);
						updatedRemoteConfigValue = removeEmptyBuildNumberFilters(updatedRemoteConfigValue);

						if (!updatedRemoteConfigValue) {
							console.error('Failed to update remote config value');
							setSubmitting(false);
							return;
						}

						const validationResult = remoteConfigSchema.safeParse(updatedRemoteConfigValue);

						if (!validationResult.success) {
							console.error('Validation failed:', validationResult.error.issues);

							// Prepare the validation error messages
							const errorMessages = validationResult.error.issues.map((issue) => `${issue.message}`);

							// Show error toast with line breaks
							toast({
								title: 'Validation Error',
								description: (
									<Box>
										{errorMessages.map((msg, idx) => (
											<Box key={idx}>- {msg}</Box>
										))}
									</Box>
								),
								status: 'error',
								duration: 7000,
								isClosable: true,
								position: 'top',
							});

							setSubmitting(false);
							return;
						}

						// Check size of the JSON string, if it exceeds the limit, show a warning toast
						if (jsonSizeInfo.isExceeding) {
							toast({
								title: 'Warning',
								description: `The size of the minified JSON string exceeds the limit of ${JSON_SIZE_LIMIT_KB} KB. Please consider minifying the JSON string further.`,
								status: 'warning',
								duration: 7000,
								isClosable: true,
								position: 'top',
							});

							setSubmitting(false);
							return;
						}

						// Show success toast
						toast({
							title: 'Validation Passed',
							description: 'The configuration has been validated successfully.',
							status: 'success',
							duration: 3000,
							isClosable: true,
							position: 'top',
						});

						let req = null;
						if (updatingExistingVariable) {
							// Check for overlap in the existing Remote Config Values that has same key by excluding the current one
							const existingOneExcludedOverlapCheckList = globalData.remoteConfigValues.filter(
								(val) =>
									val.key === updatedRemoteConfigValue!.key &&
									val.uid !== updatedRemoteConfigValue!.uid &&
									val.isTest === updatedRemoteConfigValue!.isTest &&
									val.isPersonal === updatedRemoteConfigValue!.isPersonal &&
									val.isActive === true,
							);

							const overlap = detectConfigOverlap(updatedRemoteConfigValue, existingOneExcludedOverlapCheckList, segments);
							if (overlap === undefined) {
								toast({
									title: 'Validation Error',
									description: `The configuration overlaps couldn't be determined. It contains non integer or float segmentation values.`,
									status: 'warning',
									duration: 7000,
									isClosable: true,
									position: 'top',
								});
							} else if (overlap !== null) {
								toast({
									title: 'Validation Error',
									description: `The configuration overlaps with another configuration: ${overlap.key}`,
									status: 'error',
									duration: 7000,
									isClosable: true,
									position: 'top',
								});

								setSubmitting(false);
								return;
							}

							req = sendUpdateRequest(updatedRemoteConfigValue);
						} else {
							// Check for overlap in the existing Remote Config Values that has same key with the new one

							const overlapCheckList = globalData.remoteConfigValues.filter(
								(val) =>
									val.key === updatedRemoteConfigValue!.key &&
									val.isTest === updatedRemoteConfigValue!.isTest &&
									val.isPersonal === updatedRemoteConfigValue!.isPersonal &&
									val.isActive === true,
							);
							const overlap = detectConfigOverlap(updatedRemoteConfigValue, overlapCheckList, segments);

							if (overlap === undefined) {
								toast({
									title: 'Validation Error',
									description: `The configuration overlaps couldn't be determined. It contains non integer or float segmentation values.`,
									status: 'warning',
									duration: 7000,
									isClosable: true,
									position: 'top',
								});
							} else if (overlap !== null) {
								toast({
									title: 'Validation Error',
									description: `The configuration overlaps with another configuration: ${overlap.key}`,
									status: 'error',
									duration: 7000,
									isClosable: true,
									position: 'top',
								});

								setSubmitting(false);
								return;
							}
							req = sendCreateRequest(updatedRemoteConfigValue);
						}

						let isErrorCaught = false;
						req
							.then((response) => {
								const updatedRcValue = response.data as RemoteConfigValue;
								const updatedValues = globalData.remoteConfigValues.map((val) =>
									val.uid === remoteConfigValue?.uid ? updatedRcValue : val,
								);
								globalData.setRemoteConfigValues(updatedValues);
								disclosure.onClose();
							})
							.catch((e) => {
								isErrorCaught = true;
								console.error(e);
								toast({
									title: 'Error',
									description: 'An error occurred while updating the remote config value.',
									status: 'error',
									duration: 7000,
									isClosable: true,
									position: 'top',
								});
							})
							.finally(() => {
								if (!isErrorCaught) {
									setRemoteConfigValue(undefined);
								}
								hasFetchedData.current = false;
								setSubmitting(false);
								setIsJsonValueEntered(false);
								setIsJsonValid(false);
								setJsonError('');
							});
					}}
				>
					{({ values, errors, touched, isSubmitting, setFieldValue }) => (
						<Form>
							<ModalBody>
								<Stack spacing={4}>
									<FormControl isRequired>
										<FormLabel>Name</FormLabel>
										<Field
											as={Input}
											name="key"
											placeholder="Variable Key"
											value={remoteConfigValue?.key}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												updateRemoteConfigValue({ key: e.target.value });
												setFieldValue('key', e.target.value);
											}}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Description</FormLabel>
										<Field
											as={Textarea}
											name="description"
											placeholder="Description"
											value={remoteConfigValue?.description}
											onChange={(e: any) => {
												updateRemoteConfigValue({ description: e.target.value });
												setFieldValue('description', e.target.value);
											}}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Type</FormLabel>
										<Field
											as={Select}
											name="type"
											placeholder="Select Type"
											value={remoteConfigValue?.type}
											onChange={(e: any) => {
												const selectedType = parseInt(e.target.value);
												// Set a default value based on the type
												let defaultValue: any;
												switch (selectedType) {
													case RCType.Integer:
														defaultValue = 0;
														break;
													case RCType.Float:
														defaultValue = 0.0;
														break;
													case RCType.Boolean:
														defaultValue = false;
														break;
													case RCType.String:
													default:
														defaultValue = '';
												}
												// Update the type and reset value
												updateRemoteConfigValue({ type: selectedType, value: defaultValue });
												setFieldValue('type', selectedType);
												setFieldValue('value', defaultValue);
											}}
										>
											<option value={RCType.Integer}>Integer</option>
											<option value={RCType.Float}>Float</option>
											<option value={RCType.Boolean}>Boolean</option>
											<option value={RCType.String}>String</option>
										</Field>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Value</FormLabel>
										{remoteConfigValue?.type === RCType.Boolean ? (
											<Field
												as={Select}
												name="value"
												value={remoteConfigValue.value.toString()}
												onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
													updateRemoteConfigValue({ value: e.target.value === 'true' });
													setFieldValue('value', e.target.value === 'true');
												}}
											>
												<option value="true">True</option>
												<option value="false">False</option>
											</Field>
										) : remoteConfigValue?.type === RCType.Integer ? (
											<NumberInput
												value={remoteConfigValue.value}
												onChange={(valueString, valueNumber) => {
													if (!isNaN(valueNumber)) {
														updateRemoteConfigValue({ value: Math.floor(valueNumber) });
														setFieldValue('value', valueNumber);
													}
												}}
												precision={0}
											>
												<NumberInputField name="value" />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										) : remoteConfigValue?.type === RCType.Float ? (
											<NumberInput
												defaultValue={remoteConfigValue.value}
												onChange={(valueString) => {
													const parsedValue = parseFloat(valueString);
													if (!isNaN(parsedValue)) {
														updateRemoteConfigValue({ value: parsedValue });
														setFieldValue('value', parsedValue);
													}
												}}
												precision={9}
												step={0.0000001}
												//format={(value) => (value === '' ? '' : parseFloat(value).toString())} // Remove trailing zeros
											>
												<NumberInputField
													name="value"
													pattern="^-?\d*(\.\d*)?$" // Allows only valid float input
												/>
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										) : (
											<Field
												as={Textarea}
												name="value"
												placeholder="Value"
												value={remoteConfigValue?.value}
												onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
													try {
														const isValidJson = validateJson(e.target.value);
														updateRemoteConfigValue({ value: e.target.value });
														setFieldValue('value', e.target.value);

														if (isValidJson) {
															const currentSizeBytes = new TextEncoder().encode(e.target.value).length;
															const minifiedSizeBytes = new TextEncoder().encode(JSON.stringify(JSON.parse(e.target.value))).length;
															setJsonSizeInfo({
																currentSize: (currentSizeBytes / KB_DIVISOR).toFixed(2),
																minifiedSize: (minifiedSizeBytes / KB_DIVISOR).toFixed(2),
																isExceeding: minifiedSizeBytes > JSON_SIZE_LIMIT_KB * KB_DIVISOR,
															});
														}
													} catch (e) {
														console.error(e);
													}
												}}
												backgroundColor={
													!remoteConfigValue?.value ? undefined : isJsonValueEntered && (isJsonValid ? 'green.700' : 'red.700')
												}
											/>
										)}
										{!remoteConfigValue?.value
											? undefined
											: remoteConfigValue?.type === RCType.String &&
												isJsonValueEntered &&
												!isJsonValid && <FormHelperText color="red.500">{jsonError}</FormHelperText>}
										{remoteConfigValue?.type === RCType.String && isJsonValueEntered && isJsonValid && (
											<FormHelperText color={jsonSizeInfo?.isExceeding ? 'red.500' : 'green.500'}>
												{`Current size of string: ${jsonSizeInfo.currentSize} KB --- Minified size of string: ${jsonSizeInfo.minifiedSize} KB`}
											</FormHelperText>
										)}
									</FormControl>
									<Field name="isPersonal">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} isInvalid={form.errors.isPersonal && form.touched.isPersonal}>
												<FormLabel>Privacy Mode</FormLabel>
												<Select
													{...field}
													value={remoteConfigValue?.isPersonal ? 'isPersonal' : 'isPublic'}
													onChange={(e) => {
														onPrivacyModeChange(e.target.value);
														setFieldValue('isPersonal', parseInt(e.target.value));
													}}
													placeholder="Select Privacy Mode"
												>
													<option value="isPersonal">Private</option>
													<option value="isPublic">Public</option>
												</Select>
												<FormErrorMessage>{form.errors.isPersonal}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="isTest">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} isInvalid={form.errors.isTest && form.touched.isTest}>
												<FormLabel>Select Environment</FormLabel>
												<Select
													{...field}
													value={remoteConfigValue?.isTest ? 'development' : 'production'}
													isDisabled={isEnvironmentFilterDisabled || !isAdminOrProduct}
													onChange={(e) => {
														onEnvironmentChange(e.target.value);
														setFieldValue('isTest', parseInt(e.target.value));
													}}
													placeholder="Select platform"
												>
													<option value="development">Development</option>
													<option value="production">Production</option>
												</Select>
												<FormErrorMessage>{form.errors.isTest}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="platform">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} isInvalid={form.errors.platform && form.touched.platform}>
												<FormLabel>Select Platform</FormLabel>
												<Select
													{...field}
													value={remoteConfigValue?.platform}
													onChange={(e) => {
														updateRemoteConfigValue({ platform: parseInt(e.target.value) });
														setFieldValue('platform', parseInt(e.target.value));
													}}
												>
													<option value={Platform.All}>All</option>
													<option value={Platform.iOS}>iOS</option>
													<option value={Platform.Android}>Android</option>
												</Select>
												<FormErrorMessage>{form.errors.platform}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="segment">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
												<FormLabel>Select Segment</FormLabel>
												<HStack spacing={4} paddingTop={'10px'}>
													<Select
														value={getSegmentFilter().Condition}
														width={'200px'}
														onChange={(e: any) => {
															if (e && e.target.value) {
																updateSegmentCondition(parseInt(e.target.value));
																//form.setFieldValue('countryFilter', parseInt(e.target.value));
																//updateAbTestModal({countryFilter: parseInt(e.target.value)});
																field.onChange(e);
															}
														}}
													>
														<option value={FilterCondition.NoOp}>Select Filter</option>
														<option value={FilterCondition.Include}>Include</option>
														<option value={FilterCondition.Exclude}>Exclude</option>
													</Select>
													<Box flex={1}>
														<ChakraReactSelect
															{...field}
															options={segmentNames}
															value={getSelectedSegments()}
															isMulti
															onChange={(e: any) => {
																if (e) {
																	updateSegmentFilter(e as multiSelection[]);
																}
															}}
														/>
													</Box>
												</HStack>
												<FormErrorMessage>{form.errors.segment}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="country">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country && form.touched.country}>
												<FormLabel>Select Country</FormLabel>
												<HStack spacing={4} paddingTop={'10px'}>
													<Select
														value={getCountryFilter().Condition}
														width={'200px'}
														onChange={(e: any) => {
															if (e && e.target.value) {
																//form.setFieldValue('countryFilter', parseInt(e.target.value));
																updateCountryFilter({
																	Filter: FilterType.Country,
																	Condition: parseInt(e.target.value),
																	Values: getCountryFilter().Values,
																});
																//updateAbTestModal({countryFilter: parseInt(e.target.value)});
																field.onChange(e);
															}
														}}
													>
														<option value={0}>Select Filter</option>
														<option value={7}>Include</option>
														<option value={8}>Exclude</option>
													</Select>
													<Box flex={1}>
														<ChakraReactSelect
															{...field}
															options={countryList}
															value={getSelectedCountries()}
															isMulti
															onChange={(e: any) => {
																if (e) {
																	updateCountrySelection(e as multiSelection[]);
																	//updateAbTestModal({country: e as { value: string, label: string }[]});
																}
																//field.onChange(e);
															}}
														/>
													</Box>
												</HStack>
												<FormErrorMessage>{form.errors.country}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="version">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.version && form.touched.version}>
												<FormLabel>Version Filters</FormLabel>
												{remoteConfigValue?.filterOperations
													.filter((filter) => filter.Filter === FilterType.Version)
													.map((filter, index) => (
														<HStack spacing={4} paddingTop={'10px'} key={index}>
															<Field
																as={Select}
																name={`filters.${index}.Condition`}
																value={filter.Condition}
																onChange={(e: any) => {
																	updateVersionFilterCondition(index, parseInt(e.target.value));
																	setFieldValue(`filters.${index}.Condition`, parseInt(e.target.value));
																}}
															>
																{Object.entries(FilterCondition)
																	.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
																	.map(([key, condition]) => (
																		<option key={key} value={condition}>
																			{key}
																		</option>
																	))}
															</Field>
															<Box flex={1}>
																<Field
																	as={Input}
																	name={`filters.${index}.Values`}
																	value={getVersionFilterValue(filter)}
																	placeholder="Filter Value"
																	width={'450px'}
																	onChange={(e: any) => {
																		updateVersionFilterValue(index, e.target.value);
																		setFieldValue(`filters.${index}.Values`, e.target.value);
																	}}
																/>
															</Box>
															{index === remoteConfigValue.filterOperations.filter((f) => f.Filter === FilterType.Version).length - 1 ? (
																<Button width={'350px'} colorScheme="blue" onClick={addVersionFilter}>
																	Add Filter
																</Button>
															) : (
																<Button width={'350px'} colorScheme="red" onClick={() => removeVersionFilter(index)}>
																	Remove Filter
																</Button>
															)}
														</HStack>
													))}
												<FormErrorMessage>{form.errors.version}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="buildNumber">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.buildNumber && form.touched.buildNumber}>
												<FormLabel>Build Number Filters</FormLabel>
												{remoteConfigValue?.filterOperations
													.filter((filter) => filter.Filter === FilterType.BuildNumber)
													.map((filter, index) => (
														<HStack spacing={4} paddingTop={'10px'} key={index}>
															<Field
																as={Select}
																name={`filters.${index}.Condition`}
																value={filter.Condition}
																onChange={(e: any) => {
																	updateBuildNumberFilterCondition(index, parseInt(e.target.value));
																	setFieldValue(`filters.${index}.Condition`, parseInt(e.target.value));
																}}
															>
																{Object.entries(FilterCondition)
																	.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
																	.map(([key, condition]) => (
																		<option key={key} value={condition}>
																			{key}
																		</option>
																	))}
															</Field>
															<Box flex={1}>
																<Field
																	as={Input}
																	name={`filters.${index}.Values`}
																	value={getBuildNumberFilterValue(filter)}
																	placeholder="Filter Value"
																	width={'450px'}
																	onChange={(e: any) => {
																		updateBuildNumberFilterValue(index, e.target.value);
																		setFieldValue(`filters.${index}.Values`, e.target.value);
																	}}
																/>
															</Box>
															{index ===
															remoteConfigValue.filterOperations.filter((f) => f.Filter === FilterType.BuildNumber).length - 1 ? (
																<Button width={'350px'} colorScheme="blue" onClick={addBuildNumberFilter}>
																	Add Filter
																</Button>
															) : (
																<Button width={'350px'} colorScheme="red" onClick={() => removeBuildNumberFilter(index)}>
																	Remove Filter
																</Button>
															)}
														</HStack>
													))}
												<FormErrorMessage>{form.errors.buildNumber}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
								</Stack>
							</ModalBody>
							<ModalFooter>
								<HStack justifyContent="space-between" alignItems="center" width="100%">
									<Checkbox
										isDisabled={!isMockCreator}
										isChecked={isMock}
										onChange={() => {
											const newIsMock = !isMock; // Calculate the new value
											setIsMock(newIsMock); // Update local state
											updateRemoteConfigValue({ isMock: newIsMock }); // Use the new value
										}}
									>
										Is Mock
									</Checkbox>
									<HStack spacing={3}>
										<Button variant="outline" mr={3} onClick={disclosure.onClose}>
											Cancel
										</Button>
										{updatingExistingVariable ? (
											<Button
												isDisabled={!isAdminOrProduct && environment === 'production'}
												colorScheme="blue"
												type="submit"
												isLoading={isSubmitting}
											>
												Update
											</Button>
										) : (
											<Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
												Create
											</Button>
										)}
									</HStack>
								</HStack>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default RemoteConfigVariableModal;
