import { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import ABTestModal from './add_ab_test_modal';
import ABTestTable from './components/ab_test_table';
import { ABTestHeader } from './components/ab_test_header';
import config from '../config';
import axios from 'axios';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import globalStore from '../store';
import { ViewFilter } from './ab_test_view_filter';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import { ABTestStreamView } from '@repo/alictus-common/types/ab_test';
import { DeleteABTestModal } from './delete_ab_test_modal';

function ABTestView({ userToken, selectedGameId }: { userToken: string; selectedGameId: string | null }) {
	let store = globalStore();
	const [loading, setLoading] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	const deleteABTestDisclosure = useDisclosure();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [viewFilter, setViewFilter] = useState(new ViewFilter());
	const [deleteABTestData, setDeleteABTestData] = useState<any>(null);
	const [remoteAbTests, setRemoteAbTests] = useState<ABTestStreamView[]>([]);
	const [segmentationData, setSegmentationData] = useState<PlayerSegment[]>([]);
	const [isSegmentationDataFetched, setIsSegmentationDataFetched] = useState(false);
	const [isRemoteConfigDataFetched, setIsRemoteConfigDataFetched] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;
		setIsAdmin(store.user!.roles.includes('ADMIN'));
		const fetchData = async () => {
			try {
				while (true) {
					let bearerToken = 'Bearer ' + store.JWTToken;
					let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/ab_test/stream/0';
					const response = await axios.get(url, { headers: { Authorization: bearerToken }, signal });
					let abTestStreamViews = response.data as ABTestStreamView[];
					abTestStreamViews = abTestStreamViews.filter(
						(abTestStreamView) => store.user!.roles.includes('MOCK_CREATOR') || !abTestStreamView.abTest.isMock,
					);
					setRemoteAbTests(abTestStreamViews);
					setLoading(false);
					await new Promise((resolve) => setTimeout(resolve, 5000));
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('Fetch request was canceled:', error.message);
				} else {
					console.error('Error fetching AB tests:', error);
				}
			} finally {
				setLoading(false);
			}
		};

		fetchData();

		const fetchRemoteConfigData = async () => {
			try {
				let bearerToken = 'Bearer ' + store.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/rc_package/stream/0/all';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let incomingRemoteConfigValues = response.data as RemoteConfigValue[];
				if (incomingRemoteConfigValues) {
					//Filter RC data to show values that have same name only once
					incomingRemoteConfigValues = incomingRemoteConfigValues.filter(
						(value, index, self) => self.findIndex((t) => t.key === value.key) === index,
					);

					//Filter isMock values
					incomingRemoteConfigValues = incomingRemoteConfigValues.filter(
						(value) => store.user!.roles.includes('MOCK_CREATOR') || !value.isMock,
					);

					// Sort the remote config values by key
					incomingRemoteConfigValues.sort((a, b) => (a.key > b.key ? 1 : -1));

					store.setRemoteConfigValues(incomingRemoteConfigValues);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('Fetch request was canceled:', error.message);
				} else {
					console.error('Error fetching remote config data:', error);
				}
			} finally {
				setLoading(false);
			}
		};

		const fetchSegmentationData = async () => {
			try {
				let bearerToken = 'Bearer ' + store.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/segment';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let playerSegments = response.data as PlayerSegment[];

				setSegmentationData(playerSegments);
				setViewFilter((prevFilter) => {
					const newFilter = new ViewFilter();
					Object.assign(newFilter, prevFilter);
					newFilter.setSegmentationData(playerSegments);
					return newFilter;
				});
			} catch (error) {
				console.error('Error fetching segmentation data:', error);
			} finally {
				setLoading(false);
			}
		};

		if (!isRemoteConfigDataFetched) {
			setIsRemoteConfigDataFetched(true);
			fetchRemoteConfigData();
		}
		if (!isSegmentationDataFetched) {
			fetchSegmentationData();
			setIsSegmentationDataFetched(true);
		}
		return () => controller.abort();
	}, [store, isSegmentationDataFetched, isRemoteConfigDataFetched]);

	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={6} padding={'10px'}>
				{userToken && selectedGameId && isOpen && (
					<ABTestModal title={'Create AB Test'} isOpen={isOpen} onClose={onClose} abTestToUpdate={undefined} />
				)}
				{selectedGameId !== null && (
					<Flex justifyContent="flex-end">
						<Button colorScheme="blue" size="xs" onClick={onOpen}>
							Create A/B Test
						</Button>
					</Flex>
				)}
			</GridItem>
			<GridItem paddingLeft={'5px'} paddingRight={'5px'} colSpan={6}>
				<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
					{deleteABTestDisclosure.isOpen && deleteABTestData && isAdmin && (
						<DeleteABTestModal disclosure={deleteABTestDisclosure} abTestName={deleteABTestData.name} abTestId={deleteABTestData.id} />
					)}
					<VStack spacing={1} align="stretch">
						<HStack width={'100%'}>
							{selectedGameId !== null && (
								<ABTestHeader
									viewFilter={viewFilter}
									segmentationData={segmentationData}
									setViewFilter={setViewFilter}
									remoteABTests={remoteAbTests}
								/>
							)}
						</HStack>
						{selectedGameId !== null && (
							<ABTestTable
								globalData={store}
								remoteAbTests={remoteAbTests}
								viewFilter={viewFilter}
								setRemoteAbTests={setRemoteAbTests}
								loading={loading}
								deleteABTestDisclosure={deleteABTestDisclosure}
								setDeleteABTestData={setDeleteABTestData}
								isAdmin={isAdmin}
							/>
						)}
					</VStack>
				</Box>
			</GridItem>
		</Grid>
	);
}

export default ABTestView;
